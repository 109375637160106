import { useCallback, useEffect, useState } from 'react';
// import { ApiError } from '@OpenApi/index';

type GetMeProps<T> = {
  remoteMethod: () => T;
  lazy: boolean;
};

export const useRemoteGet = <T>({ remoteMethod, lazy }: GetMeProps<T>) => {
  type A = Awaited<Promise<PromiseLike<ReturnType<typeof remoteMethod>>>>;

  const [data, setData] = useState<A>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const getRemoteContent = useCallback(async () => {
    try {
      setLoading(true);
      const result = await remoteMethod();
      if (typeof result !== 'undefined') {
        setData(result);
      }
      setError(undefined);
    } catch (e) {
      // if (e instanceof ApiError) {
      //   setError(e.message);
      // }
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [remoteMethod]);

  useEffect(() => {
    if (!lazy) {
      getRemoteContent();
    }
  }, []);

  return {
    data,
    loading,
    error,
    fetch: getRemoteContent
  };
};
