import { useCallback, useState } from 'react';
import {ApiError} from "@/services/openapi";
//import { ApiError } from '@OpenApi/index';

type GetMeProps<T> = {
    remoteMethod: () => T;
};

export const useRemotePost = <T>({ remoteMethod }: GetMeProps<T>) => {
    type A = Awaited<Promise<PromiseLike<ReturnType<typeof remoteMethod>>>>;

    const [data, setData] = useState<A>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();

  const postContent = useCallback(async () => {
    try {
      setLoading(true);
      const result = await remoteMethod();
      if (typeof result !== 'undefined') {
        setData(result);
      }
      setError(undefined);
    } catch (e) {
      if (e instanceof ApiError) {
        setError(e.message);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [remoteMethod]);

    return {
        data,
        loading,
        error,
        fetch: postContent
    };
};
