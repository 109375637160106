import styles from './Header.module.css'
import Label from '@Ui/Label'
import Img from '@Ui/Img'
import {NavLink, useNavigate} from 'react-router-dom'
import bell_icon from '@Images/NotificationBell.svg'
import logout_icon from '@Images/logout.svg'
import {useContext, useEffect, useMemo, useState} from 'react'
import AuthContext from '@/components/LoginForm/auth-context'
import MainContext from '@/contexts/MainContext'
import {JsxElement} from 'typescript'
import logo from '@Images/logo-tennant.svg'
import userIcon from '@Images/userIcon.svg'
import userIconActive from '@Images/userIconActive.svg'
import menuIcon from '@Images/menuIcon.svg'
import MenuMobile from '../MenuMobile'


const Header = () => {
    const authCtx = useContext(AuthContext);
    const titleCtx = useContext(MainContext);

    const [showMenu, setShowMenu] = useState<boolean>(false)

    const pathname = useMemo(()=>{
        console.log(window.location.pathname)
        return window.location.pathname
    },[window.location.pathname])

    const navigate = useNavigate();

    const initials = authCtx.firstName && authCtx.lastName ? authCtx?.firstName.substring(0, 1) + authCtx.lastName.substring(0, 1) : '';

    const logoutHandler = () => {
        console.log('Logging out...');
        authCtx.logout();
        window.location.reload();
    }
    return (
        <>
            <div className={styles.header_container}>
                <div className={styles.title_container}>
                    {titleCtx.pageTitle}
                    {/* {props.pageTitle} */}
                </div>
                <div className={styles.btn_container}>
                    <NavLink to={'/Notifications'}>
                        <div className={styles.bell_container}>
                            <Img className={styles.bell_icon} alt='' src={bell_icon}/>
                        </div>
                    </NavLink>
                    <NavLink to={'/Profile'} className={styles.profile_container}>
                        <div className={styles.profile_icon}>
                            <Label text={initials.toUpperCase()} className={styles.profile_letter}/>
                        </div>
                        <div className={styles.profile_name}>
                            <Label className={styles.title_name}
                                   text={authCtx.firstName && authCtx.firstName + " " + authCtx.lastName}/>
                            <Label className={styles.subtitle_name} text='Tennant Account'/>
                        </div>
                    </NavLink>
                    <div className={styles.logout_container} onClick={logoutHandler}>
                        <Img className={styles.logout_icon} alt='' src={logout_icon}/>
                    </div>


                </div>
            </div>
            {/*------------------HEADER MOBILE-------------------*/}
            <div className={styles.header_mobile}>
                <a href="/" className={styles.container_logo}>
                    <Img src={logo} className={styles.logo_top} alt='logo top' />
                </a>
                <div className={styles.container_action}>
                    <NavLink to={'/Notifications'}>
                        <div className={pathname==='/Notifications'?styles.bell_container_active:styles.bell_container}>
                            <Img className={styles.bell_icon} alt='' src={bell_icon}/>
                        </div>
                    </NavLink>
                    <NavLink to={'/Profile'}>
                        <div className={styles.user_container}>
                            <Img className={styles.user_icon} alt='' src={pathname==='/Profile'?userIconActive:userIcon}/>
                        </div>
                    </NavLink >
                    <div  onClick={()=>setShowMenu(true)}>
                        <div className={styles.menu_container}>
                            <Img className={styles.menu_icon} alt='' src={menuIcon}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={showMenu? styles.open_collapse : styles.close_collapse}>
                <MenuMobile closeMenuHandler={()=>setShowMenu(false)} logoutHandler={logoutHandler}/>
            </div>
            {/*-----------------------------------------------------*/}
        </>
    )
}

export default Header
