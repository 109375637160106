import React from 'react'
import styles from './ContentLabel.module.css'
import Label from '../UI/Label'

interface ContentLabelProps{
    containerClassname?:string
    label1: string
    label1className?: string
    label2: string
    label2className?: string
    label3?: string
    label3className?: string
}

const ContentLabel = (props: ContentLabelProps) => {
    return (

            <div className={props.containerClassname ? props.containerClassname : styles.container}>
                <div className={styles.left_container}>
                    <Label text={props.label1 ?? ""} className={props.label1className ?? ""} />
                </div>

                <div className={styles.right_container}>
                    <Label text={props.label2 ?? ""} className={props.label2className ?? ""} />
                    {props.label3 && <Label text={props.label3 ?? ""} className={props.label3className ?? ""} />}
                </div>


            </div>

    )
}

export default ContentLabel
