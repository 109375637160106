import React, {useCallback, useContext, useMemo} from "react";
import {Size} from "./WindowSizeProvider";

export type WindowSizeContextType =
    {
        size: Size
    }

export const WindowSizeContext = React.createContext<WindowSizeContextType>({
    size: {
        width: undefined,
        height: undefined
    }
});

export const useWindowContext = () => {
    const { size } = useContext(WindowSizeContext);
    
    const isMobile = useMemo(()=>{
        return (size.width)as number <= 900;
    },[size])

    return {size, isMobile};
}