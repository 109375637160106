import React from 'react'
import styles from './ProgressBar.module.css'

interface ProgressBarProps{
    bgColor?: string,
    completed?: number
    containerColor?: string
    fillerColor?: string
}

const ProgressBar = (props: ProgressBarProps) => {
    const { bgColor, completed} = props;

     
  return (
    <>
    <div className={props.containerColor ? props.containerColor : styles.containerStyles}>
      <div className={props.fillerColor ? props.fillerColor : styles.fillerStyles} style={{width: `${completed}%`, backgroundColor: bgColor}}>
      </div>
    </div>
    </>
  );
}

export default ProgressBar
