import Label from "@Ui/Label";
import styles from "./LoginForm.module.css";
import InputField from "../UI/InputField";
import Button from "@Ui/Button";
import logo from "@Images/logo-tennant.svg";
import Img from "@Ui/Img";
import loginicon from "@Images/Vector.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useRef, useContext, useEffect } from "react";
import { useRemotePost } from "@/hooks";
import { UserJwtControllerService } from "@/services/openapi";
import { LoginVM } from "@/services/openapi";

import { useNavigate } from "react-router-dom";
import AuthContext from "./auth-context";
import { OpenAPI } from "@/services/openapi";

let loginObject: LoginVM;

const LoginForm = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const [user, setUserid] = useState("");
  const [password, setPasswordId] = useState("");

  const userInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const authCtx = useContext(AuthContext);
  const history = useNavigate();

  loginObject = { username: user, password: password };

  const auth = useRemotePost({
    remoteMethod: async () => {
      if (loginObject.username && loginObject.password)
        // return CustomSitesResourceService.getAllSites1();
        return UserJwtControllerService.authorize(loginObject);
    },
  });

  useEffect(() => {
    if (typeof auth.data !== "undefined" && auth.data.id_token) {
      authCtx.login(auth.data.id_token);
    }
  }, [auth.data]);

  useEffect(() => {
    if (typeof auth.error !== "undefined") {
      setLoginError(true);
    }
  }, [auth.error]);

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  const submitHandler = async (event: any) => {
    event.preventDefault();

    // TODO: CONTROLLARE SEMPRE I DATI CHE ARRIVANO, PRIMA DI USARLI
    loginObject = { username: user, password: password };

    if (loginObject.username && loginObject.password) {
      await auth.fetch();
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <form onSubmit={submitHandler} className={styles.form_container}>
        <div className={styles.login_form}>
          <div className={styles.box}>
            <Img src={logo} className={styles.logo} alt="logo" />
            <div className={styles.login_title}>
              <Label className={styles.label_title} text="Log In" />
            </div>

            <div className={styles.credentials_div}>
              <div className={styles.couple}>
                <Label className={styles.input_label} text={t("login.email")} />
                <InputField
                  type={"text"}
                  title={"Email"}
                  required
                  placeholder={t("login.enterEmail")}
                  ref={userInputRef}
                  onChange={(e) => {
                    setUserid(e.target.value);
                  }}
                  error={loginError}
                />
              </div>
              <div className={styles.couple}>
                <div className={styles.label_container}>
                  <Label
                    className={styles.input_label}
                    text={t("login.password")}
                  />
                  <a
                    href={
                      (process.env.REACT_APP_BACKEND_URL ??
                        "http://localhost:8080") + "/account/reset/request"
                    }
                    target="_blank"
                    className={styles.navLink}
                  >
                    <p className={styles.forgotPass_label}>
                      {t("login.forgotPass")}
                    </p>
                  </a>
                </div>
                <InputField
                  type={"password"}
                  title={"Password"}
                  required
                  placeholder={t("login.enterPassword")}
                  ref={passwordInputRef}
                  onChange={(e) => {
                    setPasswordId(e.target.value);
                  }}
                  error={loginError}
                />
              </div>
            </div>
            <div className={styles.box_btn}>
              <Button
                typeSubmit={"submit"}
                onClick={() => {}}
                name={t("login.signin")}
                className={styles.login_btn}
                iconEnd={loginicon}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default LoginForm;
