import React from "react";
import styles from "./ComboSelect.module.css";
import { SelectDataProps } from "@Ui/Select/SelectBox";
import Select from "..";
import Label from "@Ui/Label";
import Img from "@Ui/Img";
import { SelectInstance } from "react-select";
import {
  ActionMeta,
  OnChangeValue,
} from "react-select/dist/declarations/src/types";

export interface ComboSelectProps {
  imgSrc?: string;
  altImg?: string;
  textLabel: string;
  nameSelect: string;
  selectData: SelectDataProps[];
  firstOption?: string;
  valueSelected?: SelectDataProps;
  multiValueSelected?: SelectDataProps[];
  ref?: React.RefObject<SelectInstance<SelectDataProps>>;
  isMulti?: boolean;
  isUserTriggered?: boolean;
  onChange?: (
    newValue: OnChangeValue<SelectDataProps, boolean>,
    actionMeta: ActionMeta<SelectDataProps>,
    isUserTriggered: boolean
  ) => void;
}

const ComboSelect = (props: ComboSelectProps) => {
  return (
    <div className={styles.ContainerComboSelect}>
      {typeof props.imgSrc !== "undefined" && (
        <div className={styles.icon_container}>
          <Img
            src={props.imgSrc}
            className={styles.icon}
            alt="{props.altImg}"
          />
        </div>
      )}
      <div className={styles.couple}>
        <div className={styles.combo_select_title}>
          <div className={styles.icon_container_mobile}>
            {typeof props.imgSrc !== "undefined" && (
              <Img src={props.imgSrc} className={styles.icon} alt="" />
            )}
          </div>
          <Label className={styles.label} text={props.textLabel} />
        </div>

        <div className={styles.ComboSelect}>
          <Select
            name={props.nameSelect}
            selectData={props.selectData}
            firstOption={props.firstOption}
            ref={props.ref}
            onChange={props.onChange}
            valueSelected={props.valueSelected}
            multiValueSelected={props.multiValueSelected}
            isMulti={props.isMulti}
            isUserTriggered={props.isUserTriggered}
          />
        </div>
      </div>
    </div>
  );
};
export default ComboSelect;
