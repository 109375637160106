/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoutesDTO } from '../models/RoutesDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomRoutesResourceService {

    /**
     * Retrieves routes by user
     * Return all routes associated with the user
     * @returns RoutesDTO OK
     * @throws ApiError
     */
    public static getRoutesByUser(): CancelablePromise<Array<RoutesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/routes-user/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves routes by site
     * Return all routes associated with the site
     * @param siteId The ID of the site to filter by
     * @returns RoutesDTO OK
     * @throws ApiError
     */
    public static getRoutesBySite(
        siteId: number,
    ): CancelablePromise<Array<RoutesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/routes-site/{siteId}',
            path: {
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves routes by multiple filters
     * Return routes filtered by site, device model and multiple device rin
     * @param siteId
     * @param deviceModel
     * @param deviceRins
     * @returns RoutesDTO OK
     * @throws ApiError
     */
    public static getRoutesByMultipleFilters(
        siteId?: number,
        deviceModel?: string,
        deviceRins?: Array<string>,
    ): CancelablePromise<Array<RoutesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/routes-multiple-filters',
            query: {
                'siteId': siteId,
                'deviceModel': deviceModel,
                'deviceRins': deviceRins,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
