/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *  DTO representing benchmark information
 */
export type BenchmarkDTO = {
    id?: number;
    type?: BenchmarkDTO.type;
    value?: number;
    deviceId?: number;
};

export namespace BenchmarkDTO {

    export enum type {
        TOTAL_DAY_PER_WEEK = 'TOTAL_DAY_PER_WEEK',
        TOTAL_HOUR_PER_DAY = 'TOTAL_HOUR_PER_DAY',
        AVERAGE_AUTONOMUS_USAGE = 'AVERAGE_AUTONOMUS_USAGE',
        ASSIST_PER_HOUR = 'ASSIST_PER_HOUR',
        TOTAL_DAY_PER_WEEK_AVG = 'TOTAL_DAY_PER_WEEK_AVG',
        TOTAL_HOUR_PER_DAY_AVG = 'TOTAL_HOUR_PER_DAY_AVG',
        AVERAGE_AUTONOMUS_USAGE_AVG = 'AVERAGE_AUTONOMUS_USAGE_AVG',
        ASSIST_PER_HOUR_AVG = 'ASSIST_PER_HOUR_AVG',
    }


}

