/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractDTO } from '../models/ContractDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomContractResourceService {

    /**
     * Retrieves the device active contract
     * Get the device active contract for the company of logged client
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param deviceId The ID of device to filter by
     * @param companyId The ID of the company to filter by
     * @returns ContractDTO OK
     * @throws ApiError
     */
    public static getActiveContract(
        dateStart: string,
        dateEnd: string,
        deviceId: number,
        companyId: string,
    ): CancelablePromise<ContractDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-active-contract',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'deviceId': deviceId,
                'companyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
