import React from 'react';
import styles from '../ReportMachine/ReportMachine.module.css';
import ReportMachineProperty from '../ReportMachineProperty';
import { useTranslation } from 'react-i18next';
import Img from '../UI/Img';
import machineIcon from '../../assets/images/standardMachine.svg';

interface ReportMachineProps {
	rank: number;
	performance: 'best' | 'under';
	rin: string;
	serialNr: string;
	site: string;
	totUtilization: string;
	totRuntime: string;
	batteryLevel: string;
	totWorkDone: string;
	suctionMotor: string;
	brushMotor: string;
	image: string;
}

const ReportToolsenseMachine = (props: ReportMachineProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.boxContainer}>
			<div className={styles.boxSubContainer}>
				<div className={styles.horizontalContainer} style={{ width: '40%' }}>
					<ReportMachineProperty
						label1={'' + props.rank}
						label1className={
							props.performance === 'best'
								? styles.rankBestNumberLabel
								: styles.rankUnderNumberLabel
						}
						label2={t('report.rank')}
						label2className={styles.rankLabel}
						textCentered={true}
					/>
					<Img className={styles.machineIcon} alt={''} src={props.image !== "" ? props.image : machineIcon} />
					<div className={styles.verticalContainer}>
						<ReportMachineProperty
							label1={t('report.serial')}
							label1className={styles.rinLabel}
							label2={props.serialNr}
							label2className={styles.rinValueLabel}
						/>
						<ReportMachineProperty
							label1={t('report.site')}
							label1className={styles.siteLabel}
							label2={props.site}
							label2className={styles.siteValueLabel}
						/>
					</div>
				</div>
				<div className={styles.verHr} />
                
				<div className={styles.gridToolsenseContainer}>
                    <ReportMachineProperty
						label1={t('machine.toolsense.total_utilization')}
						label1className={styles.propertyLabel}
						label2={props.totUtilization}
						label2className={styles.propertyValue}
					/>
					<ReportMachineProperty
						label1={t('machine.toolsense.runtime')}
						label1className={styles.propertyLabel}
						label2={props.totRuntime}
						label2className={styles.propertyValue}
					/>
					<ReportMachineProperty
						label1={t('machine.toolsense.suction_motor')}
						label1className={styles.propertyLabel}
						label2={props.suctionMotor}
						label2className={styles.propertyValue}
					/>
					<ReportMachineProperty
						label1={t('machine.toolsense.battery_level')}
						label1className={styles.propertyLabel}
						label2={props.batteryLevel}
						label2className={styles.propertyValue}
					/>
					<ReportMachineProperty
						label1={t('machine.toolsense.total_work_done')}
						label1className={styles.propertyLabel}
						label2={props.totWorkDone}
						label2className={styles.propertyValue}
					/>
					<ReportMachineProperty
						label1={t('machine.toolsense.brush_motor')}
						label1className={styles.propertyLabel}
						label2={props.brushMotor}
						label2className={styles.propertyValue}
					/>
				</div>
			</div>
		</div>
	);
};

export default ReportToolsenseMachine;
