import React from 'react'
import styles from './SiteAmr.module.css'
import Img from '../UI/Img'
import Label from '../UI/Label'
import ProgressBar from '../UI/ProgressBar'

interface SiteAmrProps {
    type: string
    id: number
    titleImg?: string
    titleImgClassName?: string;
    titleOverlayImg?: string
    topSmallTitle?: string
    titleText?: string
    divider1?: boolean
    number?: number
    label1?: string
    progressBarColor?: string
    progressBarStatus?: number
    label2?: string
    footerText?: string


    // srcImg?: string
    // altImg?: string
    // type: string
    // idData: string
    // nameData: string
    // valueData: string
    // minData: string
    // maxData: string
    // classNameImage: string
    // textLabel: string
    // classNameLabel: string
    // nameSelect: string
    // buttonName?: string
    // selectData: SelectDataProps[]

}
const SiteAmr = (props: SiteAmrProps) => {
    return (
        <div className={styles.main_container}>
            <div className={styles.header_container}>
                <Img containerStyle={styles.imgTitle_container} className={styles.imgTitle} alt={""} src={props.titleImg} overlayImg={props.titleOverlayImg} />
                <div className={styles.title_container}>
                    <Label text={props.topSmallTitle ?? ""} className={styles.topSmallTitle} />
                    <Label text={props.titleText ?? ""} className={styles.title} />
                </div>
            </div>
            <hr className={styles.hr} />
            <div className={styles.body_container}>
                <Label text={'' + props.number ?? ""} className={styles.numberLabel} />
                <Label text={props.label1 ?? ""} className={styles.machinesLabel} />
            </div>


            <ProgressBar bgColor={props.progressBarColor} completed={props.progressBarStatus} />
            <div className={styles.footer_container}>
                <Label text={props.label2 ?? ""} className={styles.label2} />
                <Label text={props.footerText ?? ""} className={styles.footerLabel} />
            </div>
        </div>
    )
}

export default SiteAmr
