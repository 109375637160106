import React from "react";
import {Area, AreaChart, CartesianGrid, ReferenceLine, Tooltip, TooltipProps, XAxis, YAxis} from 'recharts';
import styles from './LineCharts.module.css';
import {formatDate} from "@/utils";

export interface LineChartsProps {
    id: string;
    data: DataProps[],
    widthRC: string,
    heightRC: string,
    widthLineC: number,
    heightLineC: number,
    dataKey: string,
    labelX: string,
    labelY: string,
    labelY2: string
    top: number,
    right: number,
    left: number,
    bottom: number,
    color: string,
    hideX?: boolean,
    fontSizeYAxis?: string | number;
    bmLineY?: number, //Benchmark Y
    bmLineColor?: string
}

interface DataProps {

    name: string | undefined,
    uv: number | undefined,
    pv?: number,
    amt?: number,

}

const LineChart = (props: LineChartsProps) => {

    const CustomTooltip = ({payload, label, active}: TooltipProps<string, string>) => {
        if (active) {

            const squareClasses = [styles.square];

            return (<div className={styles.customTooltip}>
                <div className={styles.row}>
                    <p className={styles.labelX}>
                        <span className={styles.title}>{`${props.labelX}`}</span>{`: ${label ? formatDate(label) : ""}`}
                    </p>
                </div>
                <div className={styles.row}>
                    <div className={styles.square} style={{backgroundColor: props.color}}></div>
                    <div className={styles.labelY}>
                        <span
                            className={styles.title}>{`${props.labelY}`}</span>{`: ${payload ? payload[0].value : ''}`}
                    </div>
                </div>
                {payload && payload.length > 1 &&
                    (
                        <div className={styles.row}>
                            <div className={squareClasses.concat(styles.squareColorSecondary).join(' ')}></div>
                            <div className={styles.labelY}>
                            <span className={styles.title}>
                                {`${props.labelY2}`}
                            </span>
                                {`: ${payload ? payload[1].value : ''}`}
                            </div>
                        </div>
                    )}
            </div>)
        }
        return null;
    }

    const filterCustomTicks = (): string[] => {
        const filteredDates: string[] = [""];
    
        props.data.forEach((item) => {
            if (item.name) {
                const parts = item.name.split('/');
                const day = parseInt(parts[0]);
                if (day === 1 || day === 15) {
                    filteredDates.push(item.name);
                }
            }
        });
    
        return filteredDates;
    };
    
    const customTicks = filterCustomTicks();
    

    return (
        <AreaChart
            width={props.widthLineC}
            height={props.heightLineC}
            data={props.data}
            margin={{
                top: props.top,
                right: props.right,
                left: props.left,
                bottom: props.bottom,
            }}
        >
            <defs>
                {/* #8BC93D */}
                <linearGradient id={`colorUv-${props.id}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={props.color} stopOpacity={0.3}/>
                    <stop offset="95%" stopColor={props.color} stopOpacity={0}/>
                </linearGradient>

                <linearGradient id={`colorPv-${props.id}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={"#FC8D41"} stopOpacity={0.3}/>
                    <stop offset="95%" stopColor={"#FC8D41"} stopOpacity={0}/>
                </linearGradient>
            </defs>
            <CartesianGrid horizontal={true} vertical={false} opacity={0.2}/>
            <XAxis
                // tickLine={false}
                axisLine={true}
                dataKey={props.dataKey}
                // tick={{opacity: '0.7', fontSize: 'calc(2px + 1vmin)'}}
                hide={props.hideX ??  true}
                ticks = {customTicks}/>
            <YAxis tickLine={false} axisLine={false}
                   tick={{opacity: '0.7', fontSize: props.fontSizeYAxis ?? 'calc(2px + 1vmin)'}}/>
            <Tooltip contentStyle={{backgroundColor: '#f2f6f6'}} content={<CustomTooltip/>}/>
            {props.bmLineY && <ReferenceLine y={props.bmLineY} stroke={props.bmLineColor} strokeDasharray="3 3"/>}
            <Area type="monotone" baseLine={8} dataKey="uv" stroke={props.color} dot={false} isAnimationActive={true}
                  fillOpacity={1} fill={`url(#colorUv-${props.id})`}/>
            <Area type="monotone" baseLine={8} dataKey="pv" stroke={"#FC8D41"} dot={false} isAnimationActive={true}
                  fillOpacity={1} fill={`url(#colorPv-${props.id})`}/>
        </AreaChart>
    );

}

export default LineChart
