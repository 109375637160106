import styles from './TableTitle.module.css'
import Label from '@Ui/Label'
import Img from '@Ui/Img'


interface Props {
    titleIcon?: string,
    titleText: string,
    // firstButtonIcon?: string,
    // firstButtonText: string,
    // secondButtonIcon?: string,
    // secondButtonText: string,
    color: "green" | "orange",
}

const TableTitle = (props: Props) => {
    return (
        <>
            <div className={styles.tableTitle_container}>
                <Img className={styles.bestPerforming}
                     containerStyle={props.color === "green" ? styles.imgBest_container : styles.imgWorst_container}
                     alt="" src={props.titleIcon ? props.titleIcon : ""}/>
                <Label text={props.titleText} className={styles.tableTitleLabel}/>
                {/*<div className={styles.tableTitleButton_container}>*/}
                {/*<Button name={props.firstButtonText} className={props.color == "green" ? styles.compareGreen : styles.compareOrange} onClick={() => { }} iconStart={props.firstButtonIcon ? props.firstButtonIcon : ""}/>*/}
                {/* <Button name={props.secondButtonText} className={styles.downloadTable} onClick={() => { }} iconStart={props.secondButtonIcon ? props.secondButtonIcon : ""} /> */}
                {/*</div>*/}
            </div>
        </>
    )
}

export default TableTitle
