/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DoubleChartsOutputDTO } from '../models/DoubleChartsOutputDTO';
import type { ReportPeriodOutputDTO } from '../models/ReportPeriodOutputDTO';
import type { ReportPeriodStrOutputDTO } from '../models/ReportPeriodStrOutputDTO';
import type { TableReportOutputDTO } from '../models/TableReportOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomReportPageResourceService {

    /**
     * Retrieves the total day machines utilization
     * Creates a report page with input data and return a result page containing the total day machine utilization
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns ReportPeriodOutputDTO OK
     * @throws ApiError
     */
    public static getTotalDayMachinesUtilization(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<ReportPeriodOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/total-day-machines-utilization',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the table report performing
     * Creates a site model device with input data and return the table report performing of machine
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param best
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns TableReportOutputDTO OK
     * @throws ApiError
     */
    public static getTableReportPerforming(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        best: boolean,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<Array<TableReportOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/table-report-performing',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
                'best': best,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the percentage daily autonomous work
     * Creates a report page with input data and return a result page containing the percentage daily autonomous work of machine
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns ReportPeriodOutputDTO OK
     * @throws ApiError
     */
    public static getDeilyPercAutonomus(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<ReportPeriodOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/daily-perc-autonomus',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the average daily total run
     * Creates a report page with input data and return a result page containing the average daily total run of machine
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns ReportPeriodStrOutputDTO OK
     * @throws ApiError
     */
    public static getDeilyAvgTotalRun(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<ReportPeriodStrOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/daily-avg-total-run',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the average daily manual work
     * Creates a report page with input data and return a result page containing the average daily manual work of machine
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns ReportPeriodStrOutputDTO OK
     * @throws ApiError
     */
    public static getDeilyAvgManual(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<ReportPeriodStrOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/daily-avg-manual',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the average daily autonomous work
     * Creates a report page with input data and return a result page containing the average daily autonomous work of machine
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns ReportPeriodStrOutputDTO OK
     * @throws ApiError
     */
    public static getDeilyAvgAutonomus(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<ReportPeriodStrOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/daily-avg-autonomus',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the average training of machine
     * Creates a report page with input data and return a result page containing the average training of machine
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns ReportPeriodStrOutputDTO OK
     * @throws ApiError
     */
    public static getAvgTraining(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<ReportPeriodStrOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/avg-training',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the average assist of machine
     * Creates a report page with input data and return a result page containing the average assist of machine
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns ReportPeriodOutputDTO OK
     * @throws ApiError
     */
    public static getAvgAssist(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<ReportPeriodOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/avg-assist',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the auto usage percentage overview for a specific time period and site
     * Generates the overview using input data and returns a map result containing the auto usage percentages
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns number OK
     * @throws ApiError
     */
    public static getAutoUsagePercentageOverview(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/auto-usage-perc-overview',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the percentage assist of machine
     * Creates a site model device with input data and return the percentage assist of machine
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns number OK
     * @throws ApiError
     */
    public static getAssistPerc(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/assist-report-perc',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the assist report page overview
     * Creates a report page with input data and return the result page containing the assist report page overview
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param siteId Site ID to filter by
     * @param machineModel Model of machine
     * @returns DoubleChartsOutputDTO OK
     * @throws ApiError
     */
    public static getAssistReportPageOverview(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        siteId?: number,
        machineModel?: string,
    ): CancelablePromise<Array<DoubleChartsOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/assist-report-page-overview',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'siteId': siteId,
                'machineModel': machineModel,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
