import React from 'react'
import Label from '../UI/Label'
import styles from './LabelBenchmarkIcon.module.css'
import Img from '../UI/Img'
import bestIcon from '@Assets/images/bestPerforming.svg'
import underIcon from '@Assets/images/underPerforming.svg'
import {BenchmarkRatingDTO} from "@/services/openapi";

interface LabelBenchmarkIconProps {
    id: string;
    label1: string;
    benchmarks: Array<BenchmarkRatingDTO>
}

const LabelBenchmarkIcon = (props: LabelBenchmarkIconProps) => {
    const indexSlash = props.label1.indexOf('/');
    const val = props.label1.substring(0, indexSlash - 1);
    const benchmark = props.label1.substring(indexSlash + 1, props.label1.length);
    let rating = false;

    switch (props.id) {
        case "totalUsagePerDay":
            rating = props.benchmarks.find(b => b.type === 'TOTAL_USAGE_AVG')?.rating || false;
            break;
        case "autonomusUsage":
            rating = props.benchmarks.find(b => b.type === 'AUTONOMUS_USAGE_AVG')?.rating || false;
            break;
        case "assistPerDay":
            const foundRating = props.benchmarks.find(b => b.type === 'ASSIST_TOT')?.rating || false;
            rating = !foundRating;
            break;
        case "manualHours":
            rating = props.benchmarks.find(b => b.type === 'MANUAL_USAGE_AVG')?.rating || false;
            break;
    }


    return (
        <div className={styles.container}>
            <Label text={val} className=''/>
            <Label text={"/"} className=''/>
            <Label text={benchmark} className={styles.columnDataBenchmarkLabel}/>
            <Img className={''} alt={''} src={rating ? bestIcon : underIcon}/>
        </div>

    )
}

export default LabelBenchmarkIcon
