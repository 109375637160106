import {CSVLink} from 'react-csv';
import Button, {ButtonProps} from '../Button/Button';
import styles from './DownloadButton.module.css';
import {useDownloadCsv} from '@/hooks/DownloadCsvHook/useDownloadCsv';
import {useEffect, useRef} from 'react';
import {useWindowContext} from '@/hooks';


type DownloadButtonProps= Omit<ButtonProps,'onClick'> & {
    columns:{ [x:string]:string }
    remoteMethod: () => any;
    filename?:string
}


const DownloadButton = ({
    name,
    className,
    remoteMethod,
    iconStart,
    iconEnd,
    iconStartClassName,
    iconEndClassName,
    typeSubmit,
    filename,
    columns
}: DownloadButtonProps) => {

    const {csvData, downloading, downloadCsvFile, resetCsvData} = useDownloadCsv({...{columns, remoteMethod}})    
    const {isMobile}= useWindowContext()
    const onClick=()=>{
        if(!downloading){//Condizione che verifica che il file non stia già scaricando
            downloadCsvFile()
        }
    };
    const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    useEffect(()=>{
        if(!downloading && csvData){
            csvLink.current?.link.click()
            resetCsvData()
        }
    },[csvData,downloading])

    return (
        <>
            <CSVLink 
                data={csvData??[[]]}
                ref={csvLink}
                filename={filename}
                target="_blank"
            />
            {!downloading && <Button  {...{name,className,onClick,iconStart,iconEnd,iconStartClassName,iconEndClassName,typeSubmit}} />}
            {downloading && <button className={className} >
                <div className={styles.loading_wheel}/>
            </button>}

        </>
    )
}


export default DownloadButton;
