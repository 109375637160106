import React from 'react'
import styles from './ReportTrend.module.css'
import Img from '../UI/Img'
import Label from '../UI/Label'
import downIcon from '../../assets/images/underPerforming.svg'
import upIcon from '../../assets/images/bestPerforming.svg'

interface ReportTrendProps{
status: "up" | "down";
}

const ReportTrend = (props: ReportTrendProps) => {
  return (
    <div className={styles.container}>
                    <Img src={props.status === "up" ? upIcon : downIcon} className={props.status === "up" ? styles.iconGreen : styles.iconOrange} alt={""}/>
                    <Label className={props.status === "up" ? styles.green : styles.orange} text={"Trend"}/>

    </div>
  )
}

export default ReportTrend
