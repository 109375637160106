/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BenchmarkRatingDTO = {
    type?: BenchmarkRatingDTO.type;
    value?: string;
    rating?: boolean;
};

export namespace BenchmarkRatingDTO {

    export enum type {
        TOTAL_DAY_PER_WEEK = 'TOTAL_DAY_PER_WEEK',
        TOTAL_HOUR_PER_DAY = 'TOTAL_HOUR_PER_DAY',
        AUTONOMUS_USAGE_PERC = 'AUTONOMUS_USAGE_PERC',
        AUTONOMUS_USAGE = 'AUTONOMUS_USAGE',
        MANUAL_USAGE = 'MANUAL_USAGE',
        TOTAL_USAGE = 'TOTAL_USAGE',
        ASSIST_PER_HOUR = 'ASSIST_PER_HOUR',
        ASSIST_TOT = 'ASSIST_TOT',
        TOTAL_DAY_PER_WEEK_AVG = 'TOTAL_DAY_PER_WEEK_AVG',
        TOTAL_HOUR_PER_DAY_AVG = 'TOTAL_HOUR_PER_DAY_AVG',
        AUTONOMUS_USAGE_PERC_AVG = 'AUTONOMUS_USAGE_PERC_AVG',
        AUTONOMUS_USAGE_AVG = 'AUTONOMUS_USAGE_AVG',
        MANUAL_USAGE_AVG = 'MANUAL_USAGE_AVG',
        TOTAL_USAGE_AVG = 'TOTAL_USAGE_AVG',
        ASSIST_PER_HOUR_AVG = 'ASSIST_PER_HOUR_AVG',
        ASSIST_TOT_AVG = 'ASSIST_TOT_AVG',
    }


}

