/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SitesDTO = {
    id?: number;
    siteId?: string;
    siteName?: string;
    siteLocality?: string;
    siteProvince?: string;
    siteCountry?: string;
    siteNumber?: string;
    dateCreation?: string;
    dateDeleted?: string;
    rowUpdatedTimestamp?: string;
    streetName?: string;
    origin?: SitesDTO.origin;
};

export namespace SitesDTO {

    export enum origin {
        BRAIN = 'Brain',
        TOOLSENSE = 'Toolsense',
        CONVENTIONAL = 'Conventional',
    }


}

