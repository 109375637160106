/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageMachineOutputDTO } from '../models/PageMachineOutputDTO';
import type { SiteMachineOutputDTO } from '../models/SiteMachineOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomToolsenseMachinePageResourceService {

    /**
     * Retrieve information about the toolsense devices in one site
     * @returns SiteMachineOutputDTO OK
     * @throws ApiError
     */
    public static toolsenseMachinePageCountDevice(): CancelablePromise<Array<SiteMachineOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/toolsenseMachines-site-count',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieve a paginated list of Toolsense machines
     * Retrieve a paginated list of Toolsense machines based on specified filter and pagination options
     * @param siteId The ID of the site to filter by
     * @param machineCategory  The category of the machine to filter by
     * @param pageNo  The page number for pagination
     * @param pageSize The number of items per page for pagination.
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageMachineOutputDTO OK
     * @throws ApiError
     */
    public static toolsenseMachinePageTable(
        siteId?: number,
        machineCategory?: string,
        pageNo?: number,
        pageSize?: number,
        sortBy?: string,
        sortDir?: string,
    ): CancelablePromise<PageMachineOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/toolsenseMachines-page-table',
            query: {
                'siteId': siteId,
                'machineCategory': machineCategory,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieve all toolsense devices model
     * @param typeMachine
     * @param siteId The ID of the site to filter by
     * @returns string OK
     * @throws ApiError
     */
    public static toolsenseMachineModel(
        typeMachine: string,
        siteId?: number,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/toolsenseMachines-machine-model',
            query: {
                'typeMachine': typeMachine,
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieve all toolsense devices categories
     * @param typeMachine
     * @param siteId The ID of the site to filter by
     * @returns string OK
     * @throws ApiError
     */
    public static toolsenseMachineCategory(
        typeMachine: string,
        siteId?: number,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/toolsenseMachines-machine-category',
            query: {
                'typeMachine': typeMachine,
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
