import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Authority,
  CustomUserExtendedResourceService,
  OpenAPI,
} from "@/services/openapi";
import { useRemoteGet } from "@/hooks";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  userId: undefined,
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  company: "",
  companyID: undefined,
  location: "",
  role: [{ name: "" }],
  login: (token: string) => {},
  logout: () => {},
});

export const AuthContextProvider = (props: any) => {
  let logoutTimer: ReturnType<typeof setTimeout>;

  const calculateRemainingTime = useCallback((expirationTime: number) => {
    const currentTime = new Date().getTime();
    return expirationTime * 1000 - currentTime;
  }, []);

  const retrieveStoredToken = useCallback(() => {
    if (localStorage.getItem("token")) {
      const storedToken = localStorage.getItem("token") ?? "";
      const storedExpirationTime = parseInt(
        localStorage.getItem("expirationTime") ?? ""
      );

      const remainingTime = calculateRemainingTime(storedExpirationTime);

      if (remainingTime <= 3600) {
        localStorage.removeItem("token");
        localStorage.removeItem("expirationTime");
        return { token: undefined, duration: undefined };
      }

      return { token: storedToken, duration: storedExpirationTime };
    } else return { token: undefined, duration: undefined };
  }, []);

  const tokenData = retrieveStoredToken();
  let initialToken: string | undefined;

  if (tokenData) {
    initialToken = tokenData.token;
    OpenAPI.TOKEN = tokenData.token;
  }

  const [token, setToken] = useState<string | undefined>(initialToken);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>(!!token);
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [company, setCompany] = useState<string | undefined>(undefined);
  const [companyID, setCompanyID] = useState<string | undefined>(undefined);
  const [location, setLocation] = useState<string | undefined>(undefined);
  const [role, setRole] = useState<Authority[] | undefined>(undefined);

  /**
   * Manage user logout and delete token info in local storage
   */
  const logoutHandler = useCallback(() => {
    setToken(undefined);
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("positionExpirationTime");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  /**
   * Get user logged info when token is set
   */
  const userData = useRemoteGet({
    remoteMethod: () => {
      if (typeof token !== "undefined") {
        return CustomUserExtendedResourceService.getUserProfile();
      }
    },
    lazy: true,
  });

  /**
   * Set jwt token at login in local storage
   */
  const loginHandler = useCallback(
    (token: string, expirationTime: string) => {
      setToken(token);
      if (!localStorage.getItem("token")) {
        localStorage.setItem("token", token);
        localStorage.setItem("expirationTime", expirationTime);
      }
      setUserIsLoggedIn(!!token);

      const remainingTime = calculateRemainingTime(parseInt(expirationTime));
      logoutTimer = setTimeout(logoutHandler, remainingTime);
    },
    [token]
  );

  const loggerReturn = (context: any) => {
    return context;
  };

  useEffect(() => {
    if (typeof token !== "undefined") {
      userData.fetch();
    }
  }, [token]);

  /**
   * Set user info when userData is fetched
   */
  useEffect(() => {
    if (typeof userData.data !== "undefined") {
      setFirstName(userData.data.userName);
      setLastName(userData.data.userSurname);
      setUserId(userData.data.userId);
      setEmail(userData.data.email);
      setPassword(userData.data.password);
      setCompany(userData.data.companyName);
      setCompanyID(userData.data.companyId);
      setRole(userData.data.role);
      setLocation(userData.data.location);
    }
  }, [userData]);

  /**
   * Manage user login. Called in LoginForm
   */
  const login = (token: string) => {
    setUserIsLoggedIn(!!token);
    loginHandler(
      token,
      JSON.parse(window.atob(token.split(".")[1]).toString()).exp
    );
  };

  const contextValue = useMemo(() => {
    return {
      token,
      isLoggedIn: userIsLoggedIn,
      userId,
      firstName,
      lastName,
      email,
      password,
      company,
      companyID,
      location,
      role,
      login: login,
      //login: setToken
      logout: logoutHandler,
    };
  }, [token, userData]);

  return (
    <AuthContext.Provider value={loggerReturn(contextValue)}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
