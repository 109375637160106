import React, { useTransition } from 'react'
import styles from './ReportScore.module.css'
import Label from '../UI/Label'
import { useTranslation } from 'react-i18next'
import Img from '../UI/Img'
import starIcon from '../../assets/images/star.svg';
import filledStarIcon from '../../assets/images/fullStar.svg'
import Rating from '@mui/material/Rating';

interface ReportScoreProps{
    score: number
}

const ReportScore = (props: ReportScoreProps) => {
    const { t } = useTranslation();
    const stars = [<></>];

    stars.push(
        <Rating 
            name="half-rating-read" 
            defaultValue={0} 
            value={props.score} 
            precision={0.25} 
            emptyIcon={
                <Img className='' alt={''} src={starIcon}/>
            }
            icon={
                <Img className={styles.fillStar} alt={''} src={filledStarIcon}/>
            } 
            readOnly 
        />
    )
    

  return (
        <div className={styles.scoreContainer}>
            {stars}
        </div>
  )
}

export default ReportScore
