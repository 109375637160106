import React from 'react'
import Img from "@Ui/Img"
import Label from "@Ui/Label"
import styles from './MachineUsage.module.css'
import ProgressBar from "../UI/ProgressBar"
import ContentLabel from '../ContentLabel'

interface MachineUsageProps {
    topSmallTitle?: string
    titleText?: string
    label1?: string
    progressBarColor?: string
    progressBarStatus?: number
    label2?: string
    img?: string
    classNameContainer?:string
}

const MachineUsage = (props: MachineUsageProps) => {
    return (
        <div className={props.classNameContainer ?? styles.machineUsage_container}>
            <ContentLabel containerClassname={styles.ContentLabelContainer} label1={props.titleText + "%"} label1className={styles.machineUsage_titleLabel} label2={props.topSmallTitle ?? ""} label2className={styles.machineUsage_smallTitleLabel} />
            <ProgressBar completed={props.progressBarStatus} containerColor={styles.machineUsageProgressBar} fillerColor={styles.machineUsageProgressBarFiller} />
            {/*<div className={styles.machineUsage_footer}>*/}
            {/*    <Label text={('' + props.progressBarStatus ?? "") + "%"} className={styles.machineUsage_footerLabelColored} />*/}
            {/*    <Label text={props.label2 ?? ""} className={styles.machineUsage_footerLabel} />*/}
            {/*    <Img className={styles.machineUsage_icon} alt={""} src={props.img} />*/}
            {/*</div>*/}
        </div>
  )
}

export default MachineUsage
