import React, { useState, useCallback, useMemo } from 'react';
import MainContext from '@/contexts/MainContext'

// TODO: SPOSTARE QUESTO FILE IN UNA DIRECTORY CONTEXTS
export const MainContextProvider = (props: any) => {
    
    const [pageTitle, setPageTitle] = useState<JSX.Element>();

    const setTitleFunc = useCallback((title: JSX.Element) => {
        setPageTitle(title)
    }, []);
    
    const contextReturn = (context: any) => {
        return (
            context
        )
    }

    const contextValue = useMemo(() => {
        return {
            pageTitle,
            setTitle: setTitleFunc
        }
    }, [pageTitle]);


    return <MainContext.Provider value={contextReturn(contextValue)}>
        {props.children}
    </MainContext.Provider>;
};

export default MainContextProvider;

