import styles from './LoginPage.module.css';
import LoginForm from '@Components/LoginForm';
import ImageLogin from '@Components/ImageLogin'
const LoginPage = () => {
    return (
        <div className={styles.loginPage}>

            <div className={styles.form_container}>
                <LoginForm />
            </div>
            <div className={styles.image_container}>
                <ImageLogin />
            </div>
        </div>

    );
}

export default LoginPage;
