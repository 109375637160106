import React from 'react'
import styles from './ReportMachineProperty.module.css'
import Img from '../UI/Img'
import iconBest from '../../assets/images/bestPerforming.svg'
import iconUnder from '../../assets/images/underPerforming.svg'
import Label from '../UI/Label'

interface ReportMachinePropertyProps {
    label1: string
    label1className: string
    label2: string
    label2className: string
    trend?: "up" | "down"
    textCentered?: boolean;
}

const ReportMachineProperty = (props: ReportMachinePropertyProps) => {
    if (props.trend) {
        return (
            <div className={styles.horizontalContainer}>
                <div className={styles.iconContainer}>
                    <div className={props.trend === "up" ? styles.iconBestContainer : styles.iconUnderContainer}>
                        <Img className={props.trend === "up" ? styles.iconBest : styles.iconUnder} alt={''} src={props.trend === "up" ? iconBest : iconUnder} />
                    </div>
                </div>

                <div className={styles.verticalContainer} style={{alignItems: props.textCentered ? "center" : "start"}}>
                    <Label text={props.label1} className={props.label1className} />
                    <Label text={props.label2} className={props.label2className} />
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.verticalContainer} style={{alignItems: props.textCentered ? "center" : "start"}}>
                <Label text={props.label1} className={props.label1className} />
                <Label text={props.label2} className={props.label2className} />
            </div>
        );
    }

}

export default ReportMachineProperty
