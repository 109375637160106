/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Device } from './Device';
import type { Sites } from './Sites';

export type Benchmark = {
    id?: number;
    type?: Benchmark.type;
    value?: number;
    device?: Device;
    sites?: Sites;
};

export namespace Benchmark {

    export enum type {
        TOTAL_DAY_PER_WEEK = 'TOTAL_DAY_PER_WEEK',
        TOTAL_HOUR_PER_DAY = 'TOTAL_HOUR_PER_DAY',
        AVERAGE_AUTONOMUS_USAGE = 'AVERAGE_AUTONOMUS_USAGE',
        ASSIST_PER_HOUR = 'ASSIST_PER_HOUR',
        TOTAL_DAY_PER_WEEK_AVG = 'TOTAL_DAY_PER_WEEK_AVG',
        TOTAL_HOUR_PER_DAY_AVG = 'TOTAL_HOUR_PER_DAY_AVG',
        AVERAGE_AUTONOMUS_USAGE_AVG = 'AVERAGE_AUTONOMUS_USAGE_AVG',
        ASSIST_PER_HOUR_AVG = 'ASSIST_PER_HOUR_AVG',
    }


}

