/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TableReportToolsenseOutputDTO } from '../models/TableReportToolsenseOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomToolsenseReportPageService {

    /**
     * Retrieves the table report performing of toolsense machines
     * Creates an input with filters data and return the table report performing of machines
     * @param best
     * @param machineModel The model of toolsense machines
     * @param machineCategory The category of toolsense machines
     * @param siteId Site ID to filter by
     * @returns TableReportToolsenseOutputDTO OK
     * @throws ApiError
     */
    public static getTableReportToolsensePerforming(
        best: boolean,
        machineModel?: string,
        machineCategory?: string,
        siteId?: number,
    ): CancelablePromise<Array<TableReportToolsenseOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/table-report-toolsense-performing',
            query: {
                'machineModel': machineModel,
                'machineCategory': machineCategory,
                'siteId': siteId,
                'best': best,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieve toolsense devices utilization
     * @param siteId The ID of the site to filter by
     * @param category The machine category to filter by
     * @param model The machine model to filter by
     * @returns number OK
     * @throws ApiError
     */
    public static toolsenseMachineUtilization(
        siteId?: number,
        category?: string,
        model?: string,
    ): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/machines-utilization',
            query: {
                'siteId': siteId,
                'category': category,
                'model': model,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
