import { useWindowContext } from '@/hooks/WindowSizeContext/useWindowContext';
import styles from './Collapse.module.css';
import { useRef, useState } from 'react';
import Label from '../Label';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

interface Props {
	title: string;
	children: false | JSX.Element;
	isToolsenseData?: boolean;
}

const Collapse = (props: Props) => {
	const { size, isMobile } = useWindowContext();
	const [collapse, setCollapse] = useState<boolean>(false);
	const contentRef = useRef<HTMLDivElement>(null);

	const toggleCollapse = () => {
		setCollapse(!collapse);
	};

	const showButtons = isMobile || props.isToolsenseData;

	return (
		<>
			{(isMobile || props.isToolsenseData) && (
				<div
					className={collapse ? styles.collapseOpen : styles.collapseClose}
					onClick={toggleCollapse}
				>
					<Label
						text={props.title}
						className={collapse ? styles.titleLabelOpen : styles.titleLabelClose}
					/>
					<Label
						text={collapse ? '-' : '+'}
						className={collapse ? `${styles.titleLabelOpen} ${styles.titleLabelRight}` : `${styles.titleLabelClose} ${styles.titleLabelRight}`}
					/>
				</div>
			)}

			{(size.width as number) > 900 && !props.isToolsenseData ? (
					props.children
			) : (
				<div
					ref={contentRef}
					className={
						collapse ? styles.collapseAnimation_open : styles.collapseAnimation_close
					}
					aria-hidden={!collapse}
					style={{ height: collapse ? contentRef.current?.scrollHeight : 0 }}
				>
					{props.children}
				</div>
			)}
		</>
	);
};

export default Collapse;
