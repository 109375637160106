import {useState} from 'react';
import Row from './Row';

import closeIcon from '../../../assets/images/closeIcon.svg'

type RowProps<T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>> = {
    columns: K;
    
    record: T;
    index: number;
    colspan: number;
    hidden: boolean;
    icon: string
};

const DoubleRow = <T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>>({
    columns,
    record,
    index,
    colspan,
    hidden,
    icon
}: RowProps<T, K>) => {
    const [isHidden, setIsHidden] = useState(hidden); 

    const handleClick = () => {
        setIsHidden(!isHidden);
    }

    if( typeof record === 'object'){
        return (<>
            <Row columns={columns} data={record} index={index} onClick={handleClick} icon={isHidden ? icon : closeIcon} type={'collapsed'}/>
            <Row fullCol={true} columns={columns} data={record.period} index={0} hidden={isHidden} type={'normal'}/>
        </>);
    } else {
        
        return (<>
            <Row columns={columns} data={record} index={index} onClick={handleClick} icon={isHidden ? icon : closeIcon} type={'collapsed'}/>
            <Row fullCol={true} columns={columns} data={record} index={0} hidden={isHidden} type={'normal'}/>
    </>);
    }

};

export default DoubleRow;
