import { useCallback, useState } from "react"
import { formatDate } from "@/utils";
import i18n from "@/translations/i18n";
import { useTranslation } from "react-i18next";

type DowloadCsvProps={
    columns:{ [x:string]:string }
    remoteMethod:()=>Promise<any>
}


export const useDownloadCsv =({
    columns,
    remoteMethod
}:DowloadCsvProps) => {
    const [csvData, setCsvData] = useState<string[][] | undefined>()
    const [downloading, setDownloading] = useState<boolean>(false)
    const {t} = useTranslation()

    const parseResultToCSV=useCallback((result:any ):string[][]=>{
        if(result){
            const columnsCsv= Object.entries(columns).map((col)=>col[1])
            const dataCsv :string[][]= result.map((row:any,index:number)=>{
                const rowCsv:string[] = Object.entries(columns).map((col)=>{
                    const key = col[0]
                    if (key === 'date'
                        || key === 'data'
                        || key.endsWith('Date') 
                        || (key.endsWith('Time') && key !== 'autonomousTime')
                        || key === 'lastRun'
                        || key === 'dateStart') {
                        return formatDate(row[key] as string);
                    }
                    if (key === 'assist') {
                        if (row[key] === row[key].toUpperCase()) {
                            if (i18n.exists(`assists.reasons.${row[key]}`)) {
                                return t(`assists.reasons.${row[key]}`);
                            } else {
                                return t('assists.reason.OTHER', {assist: row[key]});
                            }
                        }
                    }
                    if(col[1]==='Rank'){
                        return (index+1).toString();
                    }
                    return row[key] as string
                })
                return rowCsv 
            })
            return [columnsCsv, ...dataCsv] //aggiungo per prima l'header della tabella
        }
        return[[]]
    },[columns, t])
    
    const downloadCsvFile= useCallback(async()=>{
        setDownloading(true)
        remoteMethod().then((res)=>{
            if(res){
                setCsvData(parseResultToCSV(res));
                setDownloading(false)
                }else{
                setDownloading(false)
            }
        }).catch((e)=>{
            console.error(e)
            setDownloading(false)
        })
    },[parseResultToCSV,  remoteMethod])

    const resetCsvData= ()=>setCsvData(undefined)

    return {
        downloadCsvFile,
        csvData,
        resetCsvData,
        downloading
    };
}