import MainContext from '@/contexts/MainContext';
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import styles from './ReportsToolsense.module.css';
import Label from '@/components/UI/Label';
import Button from '@/components/UI/Button';
import { useRemoteGet, useWindowContext } from '@/hooks';
import CloseIcon from '../../assets/images/closeIcon.svg';
import { useTranslation } from 'react-i18next';
import { SelectDataProps } from '@/components/UI/Select/SelectBox';
import { SelectInstance, SingleValue } from 'react-select';
import FilterBar from '@/components/Filter/Filterbar';
import { ComboSelectProps } from '@/components/UI/Select/ComboSelect/ComboSelect';
import machine_active_mobile from '@Images/icon_machine.svg';
import sites_active_mobile from '@Images/Icon_point.svg';
import ButtonMobile from '@/components/UI/ButtonMobile';
import downloadIcon from '../../assets/images/download.svg';
import { Box } from '@mui/material';
import CustomLoader from '@/components/UI/CustomLoader';
import Img from '@/components/UI/Img';
import Maps from '@/components/UI/Maps';
import {
	CustomSitesResourceService,
	CustomToolsenseMachinePageResourceService,
	CustomToolsenseReportPageService
} from '@/services/openapi';
import SimpleReportActivity from '@/components/ReportActivity/SimpleReportActivity';
import ReportToolsenseMachine from '@/components/ReportToolsenseMachine';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const machinesPerPage = 15;
const ReportsToolsense = () => {
	const { t } = useTranslation();
	const { size, isMobile } = useWindowContext();
	const titleCtx = useContext(MainContext);
	const [downloadReport, setDownloadReport] = useState<boolean>(false);
	const [showFiltersPage, setShowFiltersPage] = useState<boolean>(false);

	const modelRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [model, setModel] = useState<string>('All');
	const [modelIsUserTriggered, setModelIsUserTriggered] =
		useState<boolean>(false);

	const siteRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [site, setSite] = useState<string>('All');
	const [siteIsUserTriggered, setSiteIsUserTriggered] = useState<boolean>(false);

	const categoryRef = useRef<SelectInstance<SelectDataProps> | null>(null);
	const [category, setCategory] = useState<string>('All');
	const [categoryIsUserTriggered, setCategoryIsUserTriggered] =
		useState<boolean>(false);

	// const { date1, date2, setDate1, setDate2, timezone } = useFilterDateContext();
	// const date1InputRef = useRef(null);
	// const date2InputRef = useRef(null);

	const [bestPerformingIsOpen, setBestPerformingIsOpen] =
		useState<boolean>(true);
	const [underPerformingIsOpen, setUnderPerformingIsOpen] =
		useState<boolean>(true);

	useEffect(() => {
		titleCtx.setTitle(
			<Label text={t('sidebar.reports')} className={styles.titleLabel} />
		);
	}, []);

	const sitesData = useRemoteGet({
		remoteMethod: () => {
			if (model !== 'All') {
				return CustomSitesResourceService.getSitesByDeviceModel(model);
			} else {
				return CustomSitesResourceService.getAllSitesOfACertainOrigin('Toolsense');
			}
		},
		lazy: false
	});

	const deviceModelsData = useRemoteGet({
		remoteMethod: () => {
			return CustomToolsenseMachinePageResourceService.toolsenseMachineModel(
				'Toolsense',
				site !== 'All' ? parseInt(site) : undefined
			);
		},
		lazy: false
	});

	const deviceCategoriesData = useRemoteGet({
		remoteMethod: () => {
			return CustomToolsenseMachinePageResourceService.toolsenseMachineCategory(
				'Toolsense',
				site !== 'All' ? parseInt(site) : undefined
			);
		},
		lazy: false
	});

	const percentageDevData = useRemoteGet({
		remoteMethod: () => {
			return CustomToolsenseReportPageService.toolsenseMachineUtilization(
				site !== 'All' ? parseInt(site) : undefined,
				category,
				model
			);
		},
		lazy: false
	});

	const performingTableDataBest = useRemoteGet({
		remoteMethod: () => {
			return CustomToolsenseReportPageService.getTableReportToolsensePerforming(
				true,
				model,
				category,
				site !== 'All' ? parseInt(site) : undefined
			);
		},
		lazy: false
	});

	const performingTableDataWorst = useRemoteGet({
		remoteMethod: () => {
			return CustomToolsenseReportPageService.getTableReportToolsensePerforming(
				false,
				model,
				category,
				site !== 'All' ? parseInt(site) : undefined
			);
		},
		lazy: false
	});

	// Update sites filter when model change
	useEffect(() => {
		if (modelIsUserTriggered) {
			sitesData.fetch();
		}
	}, [model]);

	useEffect(() => {
		if (siteIsUserTriggered && site === 'All' && model === 'All') {
			setSiteIsUserTriggered(false);
		}
	}, [site, model]);

	// Update models and categories filter when site change
	useEffect(() => {
		if (siteIsUserTriggered) {
			deviceModelsData.fetch();
			deviceCategoriesData.fetch();
		}
	}, [site]);

	const handleBestPerf = () => {
		setBestPerformingIsOpen(!bestPerformingIsOpen);
	};

	const handleUnderPerf = () => {
		setUnderPerformingIsOpen(!underPerformingIsOpen);
	};

	const handleResetFilters = () => {
		// setDate1(moment().subtract(8, 'days').format('YYYY-MM-DD'));
		// setDate2(moment().subtract(1, 'days').format('YYYY-MM-DD'));
		setModelIsUserTriggered(true);
		setModel('All');
		setSite('All');
		setCategory('All');
	};

	const setSitesOptionSelected = () => {
		let data: SelectDataProps = { value: 'All', label: 'All' };
		if (site !== 'All' && sitesData.data) {
			const sites = sitesData.data.find((sites) => sites.id === parseInt(site));
			data = {
				value: sites?.id?.toString() ?? '',
				label: sites?.siteName ?? ''
			};
		}
		return data;
	};

	const selectSites: ComboSelectProps = {
		selectData:
			sitesData.data?.map((val) => {
				return { value: '' + val.id || '', label: val.siteName ?? '' };
			}) ?? [],
		textLabel: t('sidebar.sites'),
		nameSelect: 'selectSites',
		imgSrc: (size.width as number) > 900 ? undefined : sites_active_mobile,
		firstOption: 'All',
		ref: siteRef,
		valueSelected: setSitesOptionSelected(),
		isUserTriggered: siteIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setSiteIsUserTriggered(isUserTriggered);
			setModelIsUserTriggered(false);

			const singleValue = newValue as SingleValue<SelectDataProps>;
			if (isUserTriggered && singleValue?.value === 'All') {
				setModel('All');
			}
			if (singleValue !== null && actionMeta.action === 'select-option') {
				setSite(singleValue.value);
			}
		}
	};

	const selectModel: ComboSelectProps = {
		selectData:
			deviceModelsData.data?.map((val) => {
				return { value: val, label: val };
			}) ?? [],
		textLabel: t('dashboard.model'),
		nameSelect: 'selectModel',
		imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
		firstOption: 'All',
		ref: modelRef,
		valueSelected: { value: model, label: model },
		isUserTriggered: modelIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setModelIsUserTriggered(isUserTriggered);
			// setSiteIsUserTriggered(false);

			const singleValue = newValue as SingleValue<SelectDataProps>;
			if (singleValue !== null && actionMeta.action === 'select-option') {
				setModel(singleValue.value);
			}
		}
	};

	const selectCategory: ComboSelectProps = {
		selectData:
			deviceCategoriesData.data?.map((val) => {
				return { value: val, label: val };
			}) ?? [],
		textLabel: t('dashboard.category'),
		nameSelect: 'selectCategory',
		imgSrc: (size.width as number) > 900 ? undefined : machine_active_mobile,
		firstOption: 'All',
		ref: categoryRef,
		valueSelected: { value: category, label: category },
		isUserTriggered: categoryIsUserTriggered,
		onChange: (newValue, actionMeta, isUserTriggered) => {
			setCategoryIsUserTriggered(isUserTriggered);
			// setSiteIsUserTriggered(false);

			const singleValue = newValue as SingleValue<SelectDataProps>;
			if (singleValue !== null && actionMeta.action === 'select-option') {
				setCategory(singleValue.value);
			}
		}
	};

	const applyFilters = () => {
		// console.log("MODEL: ", model, "\nSITE: ", site);
		if (!sitesData.loading) sitesData.fetch();
		if (!deviceModelsData.loading) deviceModelsData.fetch();
		if (!deviceCategoriesData.loading) deviceCategoriesData.fetch();
		if (!percentageDevData.loading) percentageDevData.fetch();
		if (!performingTableDataBest.loading) performingTableDataBest.fetch();
		if (!performingTableDataWorst.loading) performingTableDataWorst.fetch();
	};

	const loader = () => {
		if (
			sitesData.loading ||
			deviceModelsData.loading ||
			deviceCategoriesData.loading ||
			percentageDevData.loading ||
			performingTableDataWorst.loading ||
			performingTableDataBest.loading
		)
			return (
				<span>
					<CustomLoader searchLoader={true} />
				</span>
			);
		else return <span></span>;
	};

	let pdfPages = 1;
	const tableElements: JSX.Element[][] = [];
	if (
		performingTableDataBest.data &&
		performingTableDataBest.data.length > 0
		// && performingTableDataBest.data.length > machinesPerPage
	) {
		pdfPages = Math.ceil(performingTableDataBest.data.length / machinesPerPage);

		for (let pageIndex = 0; pageIndex < pdfPages; pageIndex++) {
			const rowElement: JSX.Element[] = [];
			let lastIndex: number;
			if (pageIndex === pdfPages - 1) {
				lastIndex = performingTableDataBest.data.length;
			} else {
				lastIndex = (pageIndex + 1) * machinesPerPage;
			}

			for (let index = pageIndex * machinesPerPage; index < lastIndex; index++) {
				const val = performingTableDataBest.data[index];
				rowElement.push(
					<ReportToolsenseMachine
						rank={index + 1}
						rin={val.rin ?? ''}
						serialNr={val.serialNr ?? ''}
						key={index}
						site={val.siteName ?? ''}
						totUtilization={val.totUtilization ?? '0%'}
						totRuntime={val.totRuntime ?? '0h'}
						batteryLevel={val.batteryLevel ?? '0%'}
						totWorkDone={val.totWorkDone ?? '0m²'}
						suctionMotor={val.suctionMotor ?? '0h'}
						brushMotor={val.brushMotor ?? '0h'}
						image={val.image ?? ''}
						performance={'best'}
					/>
				);
			}
			tableElements.push(rowElement);
		}
	}

	const renderTable = tableElements.map((val, index) => {
		return (
			<div
				id={`printReportDocumentPageTable-${index}`}
				className={styles.pdf_page}
			>
				{val}
			</div>
		);
	});

	const handleReportDownload = useCallback(async () => {
		const to_hide = document.getElementsByClassName(styles.to_hide_in_report);
		try {
			setDownloadReport(true);
			for (const toHideElement of to_hide) {
				toHideElement.classList.add(styles.hidden);
			}
			const domElementPage1 = document.getElementById('printReportDocumentPage1');
			const canvas1 = await html2canvas(domElementPage1!, {
				scale: 1.5
			});
			const page1 = canvas1.toDataURL('image/png');
			const pdf = new jsPDF();
			pdf.addImage(page1, 'PNG', 7.5, 15, 210 - 15, 297 - 160);

			for (let page = 0; page < pdfPages; page++) {
				pdf.addPage();
				const domElementPage2 = document.getElementById(
					`printReportDocumentPageTable-${page}`
				);
				const canvas2 = await html2canvas(domElementPage2!, {
					scale: 1.5
				});
				const page2 = canvas2.toDataURL('image/png');
				if (page === pdfPages - 1) {
					const number = tableElements[tableElements.length - 1].length;
					const pageElementLength = (267 / machinesPerPage) * number;
					pdf.addImage(page2, 'PNG', 7.5, 15, 210 - 15, pageElementLength);
				} else {
					pdf.addImage(page2, 'PNG', 7.5, 15, 210 - 15, 297 - 30);
				}
			}

			pdf.setDrawColor('#f2f6f6');
			pdf.save('Report.pdf');
		} catch (e) {
			console.error('Download Report Error: ', e);
			setDownloadReport(false);
		} finally {
			for (const toHideElement of to_hide) {
				toHideElement.classList.remove(styles.hidden);
			}
			setDownloadReport(false);
		}
	}, [pdfPages, tableElements]);

	const allMachines = useMemo(() => {
		let countMachines =
			percentageDevData.data !== undefined
				? percentageDevData.data.over100 +
				  percentageDevData.data.between50and100 +
				  percentageDevData.data.under50
				: 0;
		return countMachines;
	}, [percentageDevData.data]);

	return (
		<>
			<div className={styles.container}>
				<div id="printReportDocumentPage1" className={styles.pdf_page}>
					{isMobile && (
						<div className={styles.title_container_header}>
							<Label text={t('sidebar.reports')} className={''} />
						</div>
					)}

					<div className={styles.horizontalContainer}>
						{/*------------------------------ Filters Desktop START --------------------------------------------------------------*/}
						{!isMobile && (
							<div className={styles.filterbar_main_container}>
								<div className={styles.filterBar_container}>
									<div className={styles.filterBar_header}>
										<Label
											text={t('AmrMachines.filters')}
											className={styles.filterLabel}
										/>
										<Button
											name={t('AmrMachines.resetFilters')}
											className={styles.resetFilters_btn}
											iconStart={CloseIcon}
											iconStartClassName={styles.resetFiltersIcon}
											onClick={handleResetFilters}
										/>
									</div>
									<div className={styles.filterBar_body}>
										<FilterBar
											filters={[
												{
													type: 'Select',
													selectData: selectModel,
													boxClassName: styles.select_container
												},
												{
													type: 'Select',
													selectData: selectCategory,
													boxClassName: styles.select_container
												},
												{
													type: 'Select',
													selectData: selectSites,
													boxClassName: styles.select_container
												}
											]}
										/>
									</div>
								</div>
							</div>
						)}
						{/*------------------------------ Filters Desktop END --------------------------------------------------------------*/}

						{/*------------------------------ FILTER MOBILE START -------------------------*/}
						{isMobile && (
							<div className={styles.filterBar_container}>
								<div className={styles.filterBar_header}>
									{/* <Label text={t('AmrMachines.filters')} className={styles.filterLabel}/> */}
									<div className={styles.filter_mobile}>
										<ButtonMobile
											text={t('assist.filters')}
											onClick={() => {
												setShowFiltersPage(!showFiltersPage);
											}}
											iconStart="filtersIcon.svg"
										/>
										{/* <Button name={t('assist.filters')} className={styles.button_mobile} iconStart={'filterIcon.svg'} onClick={()=>{}}/> */}
									</div>
								</div>
							</div>
						)}
						{/*------------------------------ FILTER MOBILE END--------------------------------------------*/}

						{/*------------------------------ DOWNLOAD --------------------------------------------*/}
						{!isMobile && (
							<Box
								className={styles.downloadContainer}
								children={
									<>
										<Label
											text={t('report.downloadReport')}
											className={styles.downloadLabel}
										/>
										{!downloadReport && (
											<Button
												name={t('report.download')}
												className={styles.downloadBtn}
												onClick={handleReportDownload}
												iconStart={downloadIcon}
											/>
										)}
										{downloadReport && (
											<button className={styles.downloadBtn}>
												<div className={styles.loading_wheel} />
											</button>
										)}
									</>
								}
							/>
						)}
					</div>

					{/*------------------------------ FILTER MOBILE COLLAPSED START -------------------------*/}
					{isMobile && (
						<div
							className={
								showFiltersPage ? styles.filterBar_body : styles.filterBar_body_hide
							}
						>
							<FilterBar
								filters={[
									{
										type: 'Select',
										selectData: selectModel,
										boxClassName: styles.select_container
									},
									{
										type: 'Select',
										selectData: selectCategory,
										boxClassName: styles.select_container
									},
									{
										type: 'Select',
										selectData: selectSites,
										boxClassName: styles.select_container
									}
								]}
							/>
							<Button
								name={t('AmrMachines.resetFilters')}
								className={styles.resetFilters_btn}
								iconStart={CloseIcon}
								iconStartClassName={styles.resetFiltersIcon}
								onClick={handleResetFilters}
							/>
						</div>
					)}
					{/*------------------------------ FILTER MOBILE COLLAPSED END -------------------------*/}

					<div className={styles.searchBtnContainer}>
						<Button
							name={t('dashboard.applyFilters')}
							className={styles.searchBtn}
							onClick={applyFilters}
						/>
						{loader()}
					</div>

					{isMobile && (
						<div className={styles.labelAllMachineContainer}>
							<Img
								alt=""
								className={styles.iconLabelAllMachine}
								src={machine_active_mobile}
							/>
							<Label
								className={styles.labelAllMachine}
								text={`Total Machine: ${allMachines}`}
							/>
						</div>
					)}

					{/*------------------------------ MAP --------------------------------------------*/}
					<div
						className={[styles.map_container, styles.to_hide_in_report].join(' ')}
					>
						<Maps className={styles.map} sites={sitesData.data ?? []} />
					</div>

					{/*------------------------------ Total Utilization START --------------------------------------*/}
					<Label text={t('report.totalUtilizationOverview')} className={styles.titleLabel}/>

					<div
						className={
							isMobile ? styles.verticalContainer : styles.horizontalContainer
						}
					>
						<SimpleReportActivity
							title={t('report.utilization100')}
							value={
								typeof percentageDevData.data?.over100 === 'undefined'
									? '0'
									: '' + percentageDevData.data?.over100
							}
							percOfFleet={
								typeof percentageDevData.data?.over100 !== 'undefined' &&
								typeof percentageDevData.data?.totalMachines !== 'undefined'
									? (
											(percentageDevData.data.over100 /
												percentageDevData.data.totalMachines) *
											100
									  ).toFixed(2) + '%'
									: '0%'
							}
						/>

						<SimpleReportActivity
							title={t('report.utilization50-100')}
							value={
								typeof percentageDevData.data?.between50and100 === 'undefined'
									? '0'
									: '' + percentageDevData.data?.between50and100
							}
							percOfFleet={
								typeof percentageDevData.data?.between50and100 !== 'undefined' &&
								typeof percentageDevData.data?.totalMachines !== 'undefined'
									? (
											(percentageDevData.data.between50and100 /
												percentageDevData.data.totalMachines) *
											100
									  ).toFixed(2) + '%'
									: '0%'
							}
						/>

						<SimpleReportActivity
							title={t('report.utilization50')}
							value={
								typeof percentageDevData.data?.under50 === 'undefined'
									? '0'
									: '' + percentageDevData.data?.under50
							}
							percOfFleet={
								typeof percentageDevData.data?.under50 !== 'undefined' &&
								typeof percentageDevData.data?.totalMachines !== 'undefined'
									? (
											(percentageDevData.data.under50 /
												percentageDevData.data.totalMachines) *
											100
									  ).toFixed(2) + '%'
									: '0%'
							}
						/>
					</div>
					{/*------------------------------ Total Utilization END --------------------------------------*/}
				</div>

				{/*------------------------------ Best/Worst Performance Machine START --------------------------------------*/}
				{!isMobile && (
						<div className={styles.button_main_container}>
							<div className={styles.button_container}>
								<Button
									name={t('sites.bestPerforming')}
									className={styles.outlined_green}
									onClick={() => {
										handleBestPerf();
									}}
								/>
								<Button
									name={t('sites.underPerforming')}
									className={styles.outlined_orange}
									onClick={() => {
										handleUnderPerf();
									}}
								/>
							</div>
						</div>
				)}

				{bestPerformingIsOpen && (
						<>
							<Label text={t('report.bestPerforming')} className={styles.titleLabel} />
							<div className={styles.verticalContainerMachineTable}>{renderTable}</div>
						</>
				)}

				{underPerformingIsOpen && (
						<>
							<Label
								text={t('report.underPerforming')}
								className={styles.titleLabel}
							/>

							<div className={styles.verticalContainerMachineTable}>
								{performingTableDataWorst.data?.map((val, index) => (
									<ReportToolsenseMachine
										rank={index + 1}
										rin={val.rin ?? ''}
										serialNr={val.serialNr ?? ''}
										key={index}
										site={val.siteName ?? ''}
										totUtilization={val.totUtilization ?? '0%'}
										totRuntime={val.totRuntime ?? '0h'}
										batteryLevel={val.batteryLevel ?? '0%'}
										totWorkDone={val.totWorkDone ?? '0m²'}
										suctionMotor={val.suctionMotor ?? '0h'}
										brushMotor={val.brushMotor ?? '0h'}
										image={val.image ?? ''}
										performance={'under'}
									/>
								))}
							</div>
						</>
				)}
			</div>
		</>
	);
};

export default ReportsToolsense;
