import React from "react";

import styles from './FilterBar.module.css'

import ComboDate from "@/components/UI/Date/ComboDate";
import ComboSelect from "@/components/UI/Select/ComboSelect";

import Label from "@/components/UI/Label";
import Button from "@/components/UI/Button"
import Checkbox from "@/components/UI/CheckBox/index";

import { ComboDateProps } from "@Ui/Date/ComboDate/ComboDate";
import { ComboSelectProps } from "@Ui/Select/ComboSelect/ComboSelect";
import { CheckboxData } from "@Ui/CheckBox/CheckBox";
import Box from "@/components/Box";
import {useTranslation} from 'react-i18next'

export interface FilterBarProps {
    //filters: Array<Filter>
    filters: Filter[]
    textLabel?: string
    textButton?: string
    checkBoxData?: CheckboxData
    showFilter?: boolean
    showFilterCallback?: () => void
}

export interface Filter {
    type: string
    selectData: ComboSelectProps | ComboDateProps
    boxClassName: string
    dataError?: boolean
    imgSrc?:string
}

const FilterBar = (props: FilterBarProps) => {

    const {t} = useTranslation();

    const checkShowFilter = () => {
        if (typeof props.showFilterCallback !== 'undefined') {
            props.showFilterCallback()
        }
    }

    let filterBarClassName: string;
    switch (props.filters.length) {
        case 1:
            filterBarClassName = ' ' + styles.ContainerFilterBar1;
            break;
        case 2:
            filterBarClassName = ' ' + styles.ContainerFilterBar2;
            break;
        case 3:
            filterBarClassName = ' ' + styles.ContainerFilterBar3;
            break;
        case 4:
            filterBarClassName = ' ' + styles.ContainerFilterBar4;
            break;
        default:
            filterBarClassName = ' ' + styles.ContainerFilterBar;
            break;
    }

    return (
        <div className={filterBarClassName}>
            {typeof props.textLabel !== 'undefined' &&
                <Label className={styles.title} text={props.textLabel} />}
            {/* <div className={styles.line}> */}
            {props.filters.map((filter, index) => {

                switch (filter.type) {
                    case "Select": {
                        return (
                            <Box key={index} className={filter.boxClassName} children={<ComboSelect {...filter.selectData as ComboSelectProps}  />} />
                        )
                    }
                    case "Date": {
                        return (
                            <Box 
                                key={index} 
                                className={styles.box_period} 
                                children={<ComboDate {...filter.selectData as ComboDateProps} dataError={filter.dataError} />} />
                                )
                    }
                    case "ButtonFilter": {
                        return (
                            <Box key={index} className={filter.boxClassName} children={
                                <div className={styles.LabelButton}>
                                    <Label className={styles.titleBox} text={t('filters.lessFilter')} />
                                    <div className={styles.container_btn}>

                                        <Button onClick={checkShowFilter} className={styles.ResetFilter_btn} name={props.showFilter ? '-' : '+'} />
                                    </div>
                                </div>} />
                        )
                    }
                }
            })}
            {/* </div> */}
            {typeof props.checkBoxData !== 'undefined' &&
                <Checkbox {...props.checkBoxData} />}
        </div>

    )



}

export default FilterBar