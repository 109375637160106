import React, {useContext} from "react";
import moment from "moment-timezone";

export type FilterDateContextType =
    {
        date1: string,
        setDate1: (value: string) => void,
        date2: string,
        setDate2: (value: string) => void,
        timezone: string
    }

export const FilterDateContext = React.createContext<FilterDateContextType>({
    date1: '',
    setDate1: () => {
    },
    date2: '',
    setDate2: () => {
    },
    timezone: moment.tz.guess()
});

export const useFilterDateContext = () => {
    const {date1, setDate1, date2, setDate2, timezone} = useContext(FilterDateContext);

    return {date1, setDate1, date2, setDate2, timezone};
}
