import React from "react";
import styles from "./ReportMachine.module.css";
import ReportMachineProperty from "../ReportMachineProperty";
import { useTranslation } from "react-i18next";
import Img from "../UI/Img";
import machineIcon from "../../assets/images/standardMachine.svg";
import { BenchmarkRatingDTO } from "@/services/openapi";

interface ReportMachineProps {
  rank: number;
  performance: "best" | "under";
  rin: string;
  site: string;
  autoDays: string;
  autoHoursDay: string;
  autoUsage: string;
  m2cleaned: string;
  totalUsage: string;
  manualHoursDay: string;
  totalAssist: number;
  distanceAuto: string;
  benchmark?: BenchmarkRatingDTO[];
}

const ReportMachine = (props: ReportMachineProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.boxContainer}>
      <div className={styles.boxSubContainer}>
        <div className={styles.horizontalContainer} style={{ width: "40%" }}>
          <ReportMachineProperty
            label1={"" + props.rank}
            label1className={
              props.performance === "best"
                ? styles.rankBestNumberLabel
                : styles.rankUnderNumberLabel
            }
            label2={t("report.rank")}
            label2className={styles.rankLabel}
            textCentered={true}
          />
          <Img className={styles.machineIcon} alt={""} src={machineIcon} />
          <div className={styles.verticalContainer}>
            <ReportMachineProperty
              label1={t("report.rin")}
              label1className={styles.rinLabel}
              label2={props.rin}
              label2className={styles.rinValueLabel}
            />
            <ReportMachineProperty
              label1={t("report.site")}
              label1className={styles.siteLabel}
              label2={props.site}
              label2className={styles.siteValueLabel}
            />
          </div>
        </div>
        <div className={styles.verHr} />
        <div className={styles.gridContainer}>
          <ReportMachineProperty
            label1={t("report.autoDays")}
            label1className={styles.propertyLabel}
            label2={props.autoDays}
            label2className={styles.propertyValue}
            trend={props.benchmark ? "up" : undefined}
          />
          <ReportMachineProperty
            label1={t("report.autoHoursDay")}
            label1className={styles.propertyLabel}
            label2={props.autoHoursDay}
            label2className={styles.propertyValue}
            trend={props.benchmark ? "up" : undefined}
          />
          <ReportMachineProperty
            label1={t("report.autoUsage")}
            label1className={styles.propertyLabel}
            label2={props.autoUsage}
            label2className={styles.propertyValue}
            trend={props.benchmark ? "up" : undefined}
          />
          <ReportMachineProperty
            label1={t("report.m2cleaned")}
            label1className={styles.propertyLabel}
            label2={props.m2cleaned}
            label2className={styles.propertyValue}
            trend={props.benchmark ? "up" : undefined}
          />
          <ReportMachineProperty
            label1={t("report.totalUsage")}
            label1className={styles.propertyLabel}
            label2={props.totalUsage}
            label2className={styles.propertyValue}
            trend={props.benchmark ? "up" : undefined}
          />
          <ReportMachineProperty
            label1={t("report.manualHoursDay")}
            label1className={styles.propertyLabel}
            label2={"" + props.manualHoursDay}
            label2className={styles.propertyValue}
            trend={props.benchmark ? "up" : undefined}
          />
          <ReportMachineProperty
            label1={t("report.totalAssist")}
            label1className={styles.propertyLabel}
            label2={"" + props.totalAssist}
            label2className={styles.propertyValue}
            trend={props.benchmark ? "up" : undefined}
          />
          <ReportMachineProperty
            label1={t("report.distanceAuto")}
            label1className={styles.propertyLabel}
            label2={props.distanceAuto}
            label2className={styles.propertyValue}
            trend={props.benchmark ? "up" : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportMachine;
