import {Wrapper, Status} from '@googlemaps/react-wrapper'
import {ReactElement, useEffect, useRef} from 'react'
import React from 'react';
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import Mapi from '../../../assets/images/MapIcon.svg'
import {SitesDTO} from "@/services/openapi";
import {useNavigate} from "react-router-dom";
import { useParams } from 'react-router-dom';

const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return <h1></h1>;
};


interface MapProps extends google.maps.MapOptions {
    style?: string;
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode;
    zoom: number;
    sites: SitesDTO[];
}

export type Location = {
    lat: number;
    lng: number;
    name: string;
    id: number;
}

const MyMapComponent: React.FC<MapProps> = ({
                                                onClick,
                                                onIdle,
                                                children,
                                                style,
                                                zoom,
                                                sites,
                                                ...options
                                            }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
    const [locations, setLocations] = React.useState<Array<Location>>([]);
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
        lat: 0,
        lng: 0,
    });

    const navigate = useNavigate();
    const { origin = "AMR" } = useParams<{ origin: string }>();


    const iconBase = "../../../assets/images/";

    //const iconBase = "https://developers.google.com/maps/documentation/javascript/examples/full/images/";

    const icons: Record<string, { icon: string }> = {
        empty: {
            icon: Mapi,
        },
        library: {
            icon: iconBase + "library_maps.png",
        },
        info: {
            icon: iconBase + "info-i_maps.png",
        },
    };

    useEffect(() => {

        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {center, zoom}));
        } else {
            map?.setZoom(zoom);
        }

        //Marker setup
        const markers = locations.map((m) => {
            const marker = new google.maps.Marker({
                position: m,
                //label: m.id,
                icon: icons["empty"].icon
            });
            marker.addListener("click", () => {
                navigate(`/Site/${origin}?id=${m.id}`);
            });
            return marker;
        });

        new MarkerClusterer({map, markers});
        setCenter(prevState => {
            if (locations.length > 0) {
                const location = {lat: locations[0].lat, lng: locations[0].lng};
                map?.setCenter(location);
                return location
            }
            return prevState;
        });

    }, [ref, map, zoom, locations]);

    useEffect(() => {
        if (sites.length > 0) {
            const geocoder = new window.google.maps.Geocoder();
            sites.map(site => {
                geocoder.geocode({
                    address: `${site.siteName ?? ''}, ${site.streetName ?? ''},${site.siteLocality ?? ''}, ${site.siteNumber ?? ''}, ${site.siteProvince ?? ''}, ${site.siteCountry ?? ''}`
                }, (response) => {
                    if (response && response.length > 0) {
                        let geocoderResult = response[0];
                        setLocations(prevState => {
                            const exists = prevState.find(location => location.id === site.id);
                            if (!exists) {
                                return [
                                    ...prevState, {
                                        lat: geocoderResult.geometry.location.lat(),
                                        lng: geocoderResult.geometry.location.lng(),
                                        name: site.siteName ?? '',
                                        id: site.id ?? 0
                                    }];
                            }
                            return prevState;
                        })
                    }
                });
            })
        }
    }, [sites]);

    return (
        <div ref={ref} id="map" className={style}/>

    );
}

interface Props {
    className?: string;
    sites: SitesDTO[];
}

const Maps = (props: Props) => {

    const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
    const [zoom, setZoom] = React.useState(3); // initial zoom


    useEffect(() => {
        setZoom(7);
    }, [])

    return (<>
        <Wrapper apiKey={process.env.REACT_APP_MAPS_KEY!}>
            <MyMapComponent
                style={props.className ?? ""}
                zoom={zoom}
                sites={props.sites}
            >

            </MyMapComponent>
        </Wrapper>
    </>)
}

export default Maps
