import React from 'react'
import Label from '../UI/Label'
import styles from './Legend.module.css'

interface LegendProps {
  containerClassname?: string
  label1: string
  label1className?: string
  label2?: string
  label2className?: string
  legendStyle: string
}

const Legend = (props: LegendProps) => {
  return (
    <div className={props.containerClassname}>
        <div className={styles.left_container}>
          <div className={props.legendStyle}></div>
        </div>

        <div className={styles.right_container}>
          <Label text={props.label1} className={props.label1className ?? ""} />
          <Label text={props.label2 ?? ""} className={props.label2className ?? ""} />
        </div>

    </div>
  )
}

export default Legend
