import React, {useContext, useEffect} from 'react'
import styles from './Notifications.module.css'
import Label from '@/components/UI/Label'
import Table from '@/components/UI/Table/Table'
import {CustomNotificationsResourceService} from '@/services/openapi'
import MainContext from '@/contexts/MainContext'
import {PagingContextProvider} from "@/contexts/providers/PagingContextProvider";
import { useWindowContext } from '@/hooks'
import notification_bell from '@Images/NotificationBell_blue.svg'
import Box from '@/components/Box'
import Img from '@/components/UI/Img'
import { t } from 'i18next'
import MachineDetailNotification from '@/components/MachineDetailNotification'
import { useRemoteGet } from '@/hooks/useRemote/useRemoteGet'
import CustomLoader from '@/components/UI/CustomLoader'

const Notifications = () => {

    const titleCtx = useContext(MainContext);
    const {isMobile} = useWindowContext()

    useEffect(() => {
        // titleCtx.setTitle(<><Label text={"" + notificationData.length + ' new '} className={styles.titleLabelOrange} /><Label text={'Notifications'} className={styles.titleLabel} /></>)
        titleCtx.setTitle(<Label text={'Notifications'} className={styles.titleLabel}/>)
        // if (notificationsData.data && notificationsData.data.length > 0) {
        //     titleCtx.setTitle(<><Label text={"" + notificationsData.data.length + ' new '}
        //                                className={styles.titleLabelOrange}/><Label text={'Notifications'}
        //                                                                            className={styles.titleLabel}/></>)
        // }

    }, [])

    const notifications = useRemoteGet({
        remoteMethod: () => {
            return CustomNotificationsResourceService.getAllNotificationsByUser( 1, 100, "date", "DESC");
        },
        lazy: false
    });

    const notificationColumns = {
        status: t('columns.notifications.status'),
        date: t('columns.notifications.date'),
        rin: t('columns.notifications.rin'),
        siteName: t('columns.notifications.siteName'),
        information: t('columns.notifications.information'),
    }

    return (
        <div className={styles.main_container}>
            {!isMobile && <div className={styles.box_container}>
                <div className={styles.box_subContainer}>
                    <Label text={'Latest Info'} className={styles.subtitleLabel}/>
                    <div className={styles.table_container}>
                        <PagingContextProvider remoteMethod={(queryParams, paging) => {
                            return () => {
                                return CustomNotificationsResourceService.getAllNotificationsByUser(paging.pageNumber,
                                    paging.pageSize,
                                    paging.sortBy || "date",
                                    paging.sortOrder || "DESC");
                            }
                        }} queryParams={{}}>
                            <Table columns={notificationColumns} type="notification" paging={true}/>
                        </PagingContextProvider>
                    </div>

                </div>

            </div>}
            {isMobile && <div className={styles.notificationContainerMobile}>
                    
                    <div className={styles.notificationTitleContainer}>
                        <Label text={t('notifications.title')} className={styles.notificationLabel} />
                    </div>
                    {notifications.loading && <CustomLoader/>}
                    {!notifications.loading && <Box className={styles.notificationMain_Container} children={
                        <>
                            <div className={styles.notificationContainer}>

                                {
                                    notifications.data?.content?.map((data, index) => {
                                        return (<><MachineDetailNotification status={data.status as string}
                                            date={data.date ?? ""}
                                            type={data.information ?? ""} />
                                            {(index + 1 !== notifications.data?.content?.length) && <div className={styles.hr} />}
                                        </>)
                                    })
                                }

                            </div>
                            {/*<div className={styles.notificationBtnContainer}>*/}
                            {/*    <Button name={'See All'} className={styles.saveBtn} onClick={() => {*/}
                            {/*    }}/>*/}
                            {/*</div>*/}
                        </>
                    } />}
                </div>}
        </div>
    )
}

export default Notifications;
