import React from 'react'
import styles from './MachineDetailNotification.module.css'
import thumbUpIcon from '@Assets/images/thumbUp.svg'
import thumpDownIcon from '@Assets/images/thumbDown.svg'
import warningIcon from '@Assets/images/warning.svg'
import Img from '../UI/Img'
import Label from '../UI/Label'
import { NotificationPageOutputDTO} from "@/services/openapi";
import {formatDate, translateAssistNotification} from "@/utils";
import {useTranslation} from "react-i18next";

interface MachineDetailNotificationProps {
    status: string
    date: string
    type: string
}

const MachineDetailNotification = (props: MachineDetailNotificationProps) => {
    const {t} = useTranslation();

    return (
        <div className={styles.container}>
            <Img
                className={
                    props.status === NotificationPageOutputDTO.status.WARNING ?
                        styles.alertNotificationIconContainer :
                        (props.status === NotificationPageOutputDTO.status.OK ?
                            styles.positiveNotificationIconContainer :
                            styles.negativeNotificationIconContainer)}
                alt={''}
                src={props.status === NotificationPageOutputDTO.status.WARNING
                    ? warningIcon :
                    (props.status === NotificationPageOutputDTO.status.OK ?
                        thumbUpIcon :
                        thumpDownIcon)}/>
            <Label text={formatDate(props.date)} className={styles.dateLabel}/>
            <Label text={translateAssistNotification(t, props.type, props.status)} className={styles.typeLabel}/>
        </div>
    )
}

export default MachineDetailNotification
