import i18n from 'i18next'
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en.json'
import itTranslation from './locales/it.json'
import frTranslation from './locales/fr.json'
import deTranslation from './locales/de.json'
import esTranslation from './locales/es.json'
import csTranslation from './locales/cs.json'
import nlTranslation from './locales/nl.json'
import ptTranslation from './locales/pt.json'

export const resources = {
  it: {
    translation: itTranslation
  },
  en: {
    translation: enTranslation
  },
  fr: {
    translation: frTranslation
  },
  de: {
    translation: deTranslation
  },
  es: {
    translation: esTranslation
  },
  cs: {
    translation: csTranslation
  },
  nl: {
    translation: nlTranslation
  },
  pt: {
    translation: ptTranslation
  }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
          resources,
          supportedLngs: ['it', 'en', 'fr', 'de', 'es', 'cs', 'nl', 'pt'],
          fallbackLng: 'en',
          debug: true
        },
        error => {
          if (error) {
            console.error("Error loading translations: ", error);
          }
        });


export default i18n;
