import styles from './Table.module.css';
import Button from '../Button';
import Label from '../Label/Label';
import { useTranslation } from 'react-i18next';
import SelectBox from '../Select/SelectBox';
import { usePagingContext } from '@/contexts/paging-context';
import { SingleValue } from 'react-select';
import { SelectDataProps } from '@Ui/Select/SelectBox';

export type FooterProps = {
	color?: 'blue' | 'green' | 'orange';
};

const Footer = ({ color }: FooterProps) => {
	const { t } = useTranslation();

	const {
		pageNumber,
		totalPages,
		prevOnClick,
		firstOnClick,
		nextOnClick,
		lastOnClick,
		setPageSize,
		setPageNumber,
		setUpdateFetch
	} = usePagingContext();
	let numberValue = pageNumber! + 1;

	return (
		<>
			<div className={styles.footer_container}>
				<div className={styles.pagesButtons_container}>
					{/*{pageSize && <>*/}
					<Button
						name="<"
						className={`${styles.footerButton} ${styles[`${color}Color`]}`}
						onClick={prevOnClick}
					/>
					<Button
						name={'' + numberValue}
						className={`${styles.footerButton} ${styles[`${color}Color`]}`}
						onClick={firstOnClick}
					/>
					<div className={styles[`${color}Color`]}>{` / `}</div>
					<Button
						name={'' + totalPages}
						className={`${styles.footerButton} ${styles[`${color}Color`]}`}
						onClick={lastOnClick}
					/>
					<Button
						name=">"
						className={`${styles.footerButton} ${styles[`${color}Color`]}`}
						onClick={nextOnClick}
					/>
					{/*</>}*/}
				</div>
				<div className={styles.rowsButton_container}>
					<Label
						text={t('sites.rowsPerPage')}
						className={`${styles.rowsButtonLabel} ${styles[`${color}Color`]}`}
					/>
					<SelectBox
						name={''}
						style={`${styles.selectPages} ${styles[`${color}Color`]}`}
						selectData={[
							{ value: '10', label: '10' },
							{ value: '50', label: '50' },
							{ value: '100', label: '100' }
						]}
						onChange={(newValue, actionMeta) => {
							setPageNumber(0);
							const singleValue = newValue as SingleValue<SelectDataProps>;
							if (singleValue !== null) {
								setPageSize(parseInt(singleValue.value));
							}
							setUpdateFetch(true);
						}}
					/>
				</div>
			</div>
		</>
	);
};
export default Footer;
