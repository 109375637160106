import styles from './SimpleReportActivity.module.css';
import Label from '../../UI/Label';
import { useTranslation } from 'react-i18next';

interface SimpleReportActivityProps {
	title: string;
	value: string;
	percOfFleet: string;
	// trend?: "up" | "down"
}
const SimpleReportActivity = (props: SimpleReportActivityProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.boxContainer}>
			<div className={styles.scoreContainer}>
				<Label text={props.title} className={styles.activityTitleLabel} />
			</div>

			<div className={styles.scoreContainer}>
				<hr className={styles.horHr} />
				<Label text={props.value} className={styles.scoreLabel} />
			</div>

			<div className={styles.scoreContainer}>
				<hr className={styles.horHr} />
				<Label text={t('report.fleet')} className={styles.activityTitleLabel} />
				<Label text={props.percOfFleet} className={styles.percentageLabel} />
			</div>
		</div>
	);
};

export default SimpleReportActivity;
