/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UsagePeriodOutputDTO = {
    dateStart?: string;
    dateEnd?: string;
    hourStartDate?: string;
    hourEndDate?: string;
    minutesStartDateToMid?: number;
    minutesEndDateToMid?: number;
    type?: UsagePeriodOutputDTO.type;
};

export namespace UsagePeriodOutputDTO {

    export enum type {
        AUTONOMOUS = 'AUTONOMOUS',
        MANUAL = 'MANUAL',
        TRAINING = 'TRAINING',
    }


}

