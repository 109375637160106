import styles from './CustomLoader.module.css'


export type CustomLoaderProps = {
    searchLoader?: boolean
}


const CustomLoader = (props: CustomLoaderProps) => {
    return (

        <div className={props.searchLoader ? styles.containerSearchCustomLoader : styles.containerCustomLoader}>
            <div className={props.searchLoader ? styles.searchCustomLoader : styles.customLoader}/>
        </div>

    )

}


export default CustomLoader
