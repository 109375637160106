import Row from './Row';
import DoubleRow from './DoubleRow';
import eyeIcon from '../../../assets/images/eye.svg'

type BodyProps<T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>> = {
    columns: K;
    data: Array<T>;
    expandedData?: Array<T>;
    type:  "sitesRanking" | "amrRanking" | "conventionalRanking" | "simpleNoAction" | "simpleAction" | "simpleMap" | "collapsed" | "notification" | "amrRankingOnlyAction" | "amrRankingWithoutAction" | "sitesRankingOnlyAction" | "sitesRankingWithoutAction"
    color?: "green" | "orange" | "blue"
};

const Body = <T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>>({
    columns,
    data,
    type,
    color
}: BodyProps<T, K>) => {
    const rows = data.map((record, index) => {
        switch(type){
            case "amrRanking":
            case "conventionalRanking":
            case "amrRankingOnlyAction":
            case "amrRankingWithoutAction":
            case "sitesRankingWithoutAction":
            case "sitesRankingOnlyAction":
            case "sitesRanking": {
                return (    
                    <>
                        <Row key={index} columns={columns} data={record} index={index} icon={eyeIcon} type={type} color={color}/>
                    </>);
            }
            case "simpleMap":
            case "simpleAction":
            case "notification":
            case "simpleNoAction": {
                return (    
                    <>
                        <Row key={index} columns={columns} data={record} index={index} icon={eyeIcon} type={type}/>
                    </>);
            }
            case "collapsed": {
                return (
                    <>
                        <DoubleRow key={index} columns={columns} record={record} index={index} colspan={0} hidden={true} icon={eyeIcon}/>
                    </>)
            }

            default:
        }


});
    return <tbody>{rows}</tbody>;
};

export default Body;
