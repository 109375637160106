import styles from './MenuMobile.module.css'
import {NavLink, useNavigate} from 'react-router-dom'
import {useContext, useEffect} from 'react'
import AuthContext from '@/components/LoginForm/auth-context'
import logo from '@Images/logo-tennant-white.svg'
import exitIcon from '@Images/exitIcon.svg'
import Img from '@/components/UI/Img'

import home_icon from '@Images/dashboard-icon-white.svg'
import logout_icon from '@Images/log-out-icon-white.svg'
import amr_icon from '@Images/AMR-icon-white.svg'
import assist_icon from '@Images/assist-white.svg'
import reports_icon from '@Images/report-icon-white.svg'
import news_icon from '@Images/news-icon-white.svg'
import analytics_icon from '@Images/analytics-icon-white.svg'
import { t } from 'i18next'

type MenuMobileProps = {
    closeMenuHandler:()=>void,
    logoutHandler:()=>void,
}

const MenuMobile = ({closeMenuHandler,logoutHandler}:MenuMobileProps) => {
    const authCtx = useContext(AuthContext);

    const navigate = useNavigate();

    const linkFooter = 'https://info.tennantco.com/?e=313724&h=29EC564D20B2DD0&l=en&q1=4&as=1'

    const isRoleTennant = authCtx.role?.some((r) => r.name === 'ROLE_TENNANT');

    const menuItem = [
        {
            path: '/Dashboard',
            name: t('sidebar.dashboard'),
            icon: <Img src={home_icon} alt='' className={styles.link_icon} />
        },{
            
            path: '/AmrMachines',
            name: t('sidebar.amrMachines'),
            icon: <Img src={amr_icon} alt='' className={styles.link_icon} />,
        },{
            path: '/Assists',
            name: t('sidebar.assists'),
            icon: <Img src={assist_icon} alt='' className={styles.link_icon} />
        },{
            path: '/Reports',
            name: t('sidebar.reports'),
            icon: <Img src={reports_icon} alt='' className={styles.link_icon} />
        },{
            path: '/News',
            name: t('sidebar.news'),
            icon: <Img src={news_icon} alt='' className={styles.link_icon} />
        }
    ]

    if (isRoleTennant) {
        menuItem.push({
            path: '/AppAnalytics',
            name: t('sidebar.appAnalytics'),
            icon: <Img src={analytics_icon} alt='' className={styles.link_icon} />
        });
    }

    return (
        <>
            <div className={styles.menu_container} >
                <div>
                    <div className={styles.intestazione}>
                        <div className={styles.logo_container}>
                            <Img className={styles.logo_icon} alt='' src={logo}/>
                        </div>
                        <div className={styles.exitIcon_container} onClick={closeMenuHandler}>
                            <Img className={styles.exitIcon_icon} alt='' src={exitIcon}/>
                        </div>
                    </div>
                    <div className={styles.routeLink_container}>
                        {
                            menuItem.map((link, index) => {
                                return (
                                    <div key={index}>
                                        <a className={styles.routeLink} href={link.path} onClick={closeMenuHandler}>
                                            <div className={styles.icon_container}>
                                                {link.icon}
                                            </div>
                                            <div className={styles.label_conatiner}>
                                                <label className={styles.label}>{link.name}</label>
                                            </div>
                                        </a>
                                        <div className={styles.delimiter}/>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                    
                <div className={styles.footer} >
                    <a className={styles.routeLink} onClick={logoutHandler}>
                        <div className={styles.icon_container_logout}>
                            <Img src={logout_icon} alt='' className={styles.link_icon_logout} />
                        </div>
                        <div className={styles.label_conatiner}>
                            <label className={styles.label}>{t('logout')}</label>
                        </div>
                    </a>
                    <div className={styles.delimiter}/>
                    <a className={styles.link_footer} href={linkFooter}>    
                        <p className={styles.link_footer_text}>{t('footer.buttonSurvey')}</p>
                    </a>
                </div>
            </div>
        </>
    )
}
export default MenuMobile
