import React, {ChangeEventHandler, useContext} from "react";
import Label from "@Ui/Label";
import Img from "@Ui/Img";
import {useTranslation} from "react-i18next";
import {PositionContext} from "@/contexts/PositionContext";
import styles from './PositionDeniedBanner.module.css'
import alertIcon from '../../assets/images/alertTriangle.svg'

const PositionDeniedBanner = (props: any) => {

  const {t} = useTranslation();
  const {positionConsent} = useContext(PositionContext);

  if (!positionConsent) {
    return (
        <div className={styles.outerBox}>
          <div className={styles.banner}>
            <Img src={alertIcon} className={styles.icon} alt=''/>
            <div className={styles.textContainer}>
              <Label
                  text={t('maps.positionDeniedBannerBody')}
                  className={styles.text}
              />
            </div>
          </div>
        </div>
    )
  } else {
    return null;
  }
}

export default PositionDeniedBanner
