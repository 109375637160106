import React from 'react'
import styles from './UsageProgressBar.module.css'
import Label from '../UI/Label'
import {UsagePeriodOutputDTO} from "@/services/openapi";
import { useWindowContext } from '@/hooks';


interface UsageProgressBarProps {
    containerColor?: string
    fillerColorUsage?: string
    fillerColorNonUsage?: string
    bottomLabel?: string
    bottomLabelClassname?: string
    hoursBase: { value: string }[]
    data: UsagePeriodOutputDTO[]
    isOpen: boolean
}


const UsageProgressBar = (props: UsageProgressBarProps) => {

    const {isMobile}= useWindowContext()
    const minutRange = isMobile?15:10;
    const labels: string[] = [];
    const loadedLabels: string[] = [];
    const labelsArr: {min?:number,hour?:string}[] = props.data.reduce((prev,next)=>{
        return [...prev,
            {min:next.minutesStartDateToMid,hour:next.hourStartDate},
            {min:next.minutesEndDateToMid,hour:next.hourEndDate}
        ]
    },[{}])
    labelsArr.sort((a,b)=>a.min!-b.min!);
    let lastMinute=0;
    labelsArr.forEach((val,i,arr)=>{
        if(i===1){
            labels.push(val.hour!); 
        }else{
            if(arr.length>2 && val.min!-lastMinute>minutRange && arr[arr.length-1].min!-val.min! >minutRange){
                labels.push(val.hour!); 
            }

            if(i===arr.length-1){
                labels.push(val.hour!); 
            }
        }
        lastMinute=val.min!


    })

    return (
        <>
            <div className={props.isOpen ? styles.main_container : styles.reduced_main_container}>
                {!props.isOpen && <div><Label text={props.hoursBase[0].value} className={styles.bottomLabel}/></div>}
                <div className={props.containerColor ? props.containerColor : styles.containerStyles}>
                {
                    props.data.map((val, i, arr) => {
                        let time = [<></>];

                        let addStart = false;
                        let addEnd = false;
                        if (labels.indexOf(val.hourStartDate!) > -1 && loadedLabels.indexOf(val.hourStartDate!) === -1) {
                            loadedLabels.push(val.hourStartDate!);
                            addStart = true;
                        }

                        if (labels.indexOf(val.hourEndDate!) > -1 && loadedLabels.indexOf(val.hourEndDate!) === -1) {
                            loadedLabels.push(val.hourEndDate!);
                            addEnd = true;
                        }

                        const addStartElement = addStart ?
                            <>
                                <Label text={val.hourStartDate!} className={`${styles.usageLabelStart} ${styles[`usageLabel${val.type!}`]}`}/>
                            </>
                            : <></>;
                        const addEndElement = addEnd ?
                            <>
                                <Label text={val.hourEndDate!} className={`${styles.usageLabelEnd} ${styles[`usageLabel${val.type!}`]}`}/>
                            </>
                            : <></>;

                        time.push(<>                         
                            <div className={styles[`progressBarFillerUsage${val.type!}`]}
                                style={{
                                width:  `${(val.minutesEndDateToMid!-val.minutesStartDateToMid!)*100/1440}%`,
                                left: `${val.minutesStartDateToMid!*100/1440}%`
                            }}>
                                {
                                    props.isOpen && addStartElement
                                }
                                {
                                    props.isOpen && addEndElement
                                }
                            </div>
                        </>)

                        return time
                    })
                }


                    {/* {

                        props.data.map((val, i, arr) => {
                            let time = [<></>];

                            const timeBeforeStart = val.minutesStartDateToMid! - totalTime;
                            const timeDiff = (val.minutesEndDateToMid! - totalTime) - timeBeforeStart;
                            totalTime += timeBeforeStart + timeDiff;

                            let addStart = false;
                            let addEnd = false;
                            if (labels.indexOf(val.hourStartDate!) > -1 && loadedLabels.indexOf(val.hourStartDate!) === -1) {
                                loadedLabels.push(val.hourStartDate!);
                                addStart = true;
                            }

                            if (labels.indexOf(val.hourEndDate!) > -1 && loadedLabels.indexOf(val.hourEndDate!) === -1) {
                                loadedLabels.push(val.hourEndDate!);
                                addEnd = true;
                            }

                            const addStartElement = addStart ?
                                <>
                                    <Label text={val.hourStartDate!} className={styles.usageLabelStart}/>
                                </>
                                : <></>;
                            const addEndElement = addEnd ?
                                <>
                                    <Label text={val.hourEndDate!} className={styles.usageLabelEnd}/>
                                </>
                                : <></>;

                            time.push(<>
                                <div
                                    className={props.fillerColorNonUsage ? props.fillerColorNonUsage : styles.fillerStyles}
                                    style={{width: `${timeBeforeStart * 0.07}%`}}>

                                </div>

                                <div className={styles[`progressBarFillerUsage${val.type!}`]}
                                     style={{width: `${timeDiff * 0.07}%`}}>
                                    {
                                        props.isOpen && addStartElement
                                    }
                                    {
                                        props.isOpen && addEndElement
                                    }
                                </div>
                            </>)
                            ;
                            if (arr.length - 1 === i) {
                                time.push(<>

                                    <div
                                        className={props.fillerColorNonUsage ? props.fillerColorNonUsage : styles.fillerStyles}
                                        style={{width: `${(1440 - totalTime) * 0.07}%`}}>
                                    </div>
                                </>)
                            }
                            return (
                                time
                            );


                        })
                    } */}
                    {

                    }
                </div>
                {!props.isOpen && <div><Label text={props.hoursBase[props.hoursBase.length - 1].value}
                                              className={styles.bottomLabel}/></div>}

                {
                    props.isOpen &&
                    <div className={styles.labelsContainer}>
                        {
                            props.hoursBase.map(value => <Label text={value.value} className={styles.bottomLabel}/>)
                        }
                    </div>
                }


            </div>

        </>
    );
}

export default UsageProgressBar
