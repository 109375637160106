/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Benchmark } from './Benchmark';
import type { Company } from './Company';
import type { Device } from './Device';
import type { DeviceHistory } from './DeviceHistory';
import type { Notifications } from './Notifications';
import type { Routes } from './Routes';
import type { RoutesRunActivity } from './RoutesRunActivity';
import type { SitesHistory } from './SitesHistory';
import type { TrainingUsage } from './TrainingUsage';
import type { UserExtended } from './UserExtended';

export type Sites = {
    id?: number;
    siteId?: string;
    siteName?: string;
    siteLocality?: string;
    siteProvince?: string;
    siteCountry?: string;
    siteNumber?: string;
    siteOwnerName?: string;
    streetName?: string;
    streetNumber?: string;
    zipCode?: string;
    dateCreation?: string;
    dateDeleted?: string;
    rowUpdatedTimestamp?: string;
    origin?: Sites.origin;
    company?: Company;
    devices?: Array<Device>;
    routes?: Array<Routes>;
    notifications?: Array<Notifications>;
    trainingUsages?: Array<TrainingUsage>;
    routesRunActivities?: Array<RoutesRunActivity>;
    benchmarks?: Array<Benchmark>;
    sitesHistories?: Array<SitesHistory>;
    deviceHistories?: Array<DeviceHistory>;
    userExtendeds?: Array<UserExtended>;
};

export namespace Sites {

    export enum origin {
        BRAIN = 'Brain',
        TOOLSENSE = 'Toolsense',
        CONVENTIONAL = 'Conventional',
    }


}

