import {defaultPaging, PagingType} from "@Ui/Table/paging";
import React, {useContext} from "react";

export const PagingContext = React.createContext<PagingType>(defaultPaging);

export const usePagingContext = () => {
    const {
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        totalPages,
        setTotalPages,
        totalElements,
        setTotalElements,
        prevOnClick,
        nextOnClick,
        firstOnClick,
        lastOnClick,
        remoteGet,
        setUpdateFetch
    } = useContext(PagingContext);

    return {
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        totalPages,
        setTotalPages,
        totalElements,
        setTotalElements,
        prevOnClick,
        nextOnClick,
        firstOnClick,
        lastOnClick,
        remoteGet,
        setUpdateFetch
    }
}
