import React from "react";


interface BoxProps {
    children: JSX.Element
    className: string
}

const Box = (props: BoxProps) => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
}

export default Box
