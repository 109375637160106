import React from 'react'
import styles from './UsageBar.module.css'
import UsageProgressBar from '../UsageProgressBar'
import {UsagePeriodOutputDTO} from "@/services/openapi";

interface UsageBarProps{
    isOpen: boolean
    data: UsagePeriodOutputDTO[];
}



const UsageBar = (props: UsageBarProps) => {
    const hours = [
        {
            value : "00:00"
        },
        {
            value : "01:00"
        },
        {
            value : "02:00"
        },
        {
            value : "03:00"
        },
        {
            value : "04:00"
        },
        {
            value : "05:00"
        },
        {
            value : "06:00"
        },
        {
            value : "07:00"
        },
        {
            value : "08:00"
        },
        {
            value : "09:00"
        },
        {
            value : "10:00"
        },
        {
            value : "11:00"
        },
        {
            value : "12:00"
        },
        {
            value : "13:00"
        },
        {
            value : "14:00"
        },
        {
            value : "15:00"
        },
        {
            value : "16:00"
        },
        {
            value : "17:00"
        },
        {
            value : "18:00"
        },
        {
            value : "19:00"
        },
        {
            value : "20:00"
        },
        {
            value : "21:00"
        },
        {
            value : "22:00"
        },
        {
            value : "23:00"
        },
    ]

    const data1 = [
        {
            inizio: "08:03",
            fine: "11:32",
            start: 483,
            end: 692 
        },
        {
            inizio: "13:00",
            fine: "14:33",
            start: 780,
            end: 873 
        },
        {
            inizio: "16:00",
            fine: "19:45",
            start: 960,
            end: 1185 
        }
    ]

  return (
    <div className={props.isOpen ? styles.main_container : styles.reduced_container}>
        <div className={styles.container}>

            <UsageProgressBar 
                isOpen={props.isOpen} 
                containerColor={props.isOpen ? styles.progressBarContainer : styles.reducedProgressBarContainer} 
                fillerColorUsage={styles.progressBarFillerUsage} 
                fillerColorNonUsage={styles.progressBarFillerNonUsage} 
                bottomLabel={""} 
                hoursBase={hours} 
                data={props.data}/>
        </div>
        
    </div>
  )
}

export default UsageBar
