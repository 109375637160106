import Img from '../Img';
import Label from '../Label';
import styles from './ButtonMobile.module.css';


type  ButtonMobileProps ={
    text: string,
    onClick: () => void
    iconStart?:string,
    iconEnd?: string,
}


const ButtonMobile = ({text, iconStart, iconEnd, onClick}: ButtonMobileProps) => {
    return (
        <>
            <button  className={styles.button_container} onClick={onClick}>
                {iconStart && <Img className={styles.button_icon_start} alt='' src={require(`@Images/${iconStart}`)}/>}
                <Label text={text} className={styles.button_label}/> 
                {iconEnd && <Img className={styles.button_icon_end} alt='' src={require(`@Images/${iconEnd}`)??''}/>}
            </button>
        </>
    )

}


export default ButtonMobile