import React from 'react'
import styles from './ReportActivity.module.css'
import Label from '../UI/Label'
import Legend from '../Legend'
import LabelsProgressBar from '../LabelsProgressBar'
import { useTranslation } from 'react-i18next'
import ReportScore from '../ReportScore'

interface ReportActivityProps {
    title: string
    currValue: string
    prevValue: string
    benchmarkValue: string
    score: number
    trend?: "up" | "down"
}
const ReportActivity = (props: ReportActivityProps) => {

    const { t } = useTranslation();
    return (
        <div className={styles.boxContainer}>
            <div className={styles.boxSubContainer}>
                <Label text={props.title} className={styles.activityTitleLabel} />
                {/*<div className={styles.legend_main_Container}>*/}
                {/*    <Legend label1={t('report.legendAutonomous')} label1className={styles.legendLabel} legendStyle={styles.legendAuton} containerClassname={styles.legendContainer} />*/}
                {/*    <Legend label1={t('report.legendManual')} label1className={styles.legendLabel} legendStyle={styles.legendMan} containerClassname={styles.legendContainer} />*/}
                {/*</div>*/}
                <div className={styles.horizontalContainer} >
                    <LabelsProgressBar type={'vertical'} label1={props.currValue} label1className={styles.titleLabelsProgressbar} label2={t('report.currPeriod')} label2className={styles.subtitleLabelsProgressbar} progressBarStatus={70} />
                    <LabelsProgressBar type={'vertical'} label1={props.prevValue} label1className={styles.titleLabelsProgressbar} label2={t('report.prevPeriod')} label2className={styles.subtitleLabelsProgressbar} progressBarStatus={50} />
                </div>
                <hr className={styles.horHr} />
                <div className={styles.horizontalContainer} style={{ width: "80%" }}>
                    <LabelsProgressBar type={'vertical'} label1={props.benchmarkValue} label1className={styles.smallTitleLabelsProgressbar} label2={t('report.benchmark')} label2className={styles.smallSubtitleLabelsProgressbar} progressBarStatus={70} />
                    <div className={styles.verHr}/>
                    <div className={styles.scoreContainer}>
                        <Label text={t('report.score')} className={styles.scoreLabel} />
                        <ReportScore score={props.score}/>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ReportActivity
