import styles from './Table.module.css';
import Footer from './Footer';
import Table, { TableProps } from './Table';


const TableMobile = <T extends Record<string, any>, K extends Partial<Record<Extract<keyof T, string>, string>>>({
    columns,
    data,
    type,
    color,
    paging
}: TableProps<T, K>) => {

    return (
        <>
            <div className={styles.tableMobile}>
                <div className={styles.scrollTableMobile}>
                    <Table {...{columns, type:(`${type}WithoutAction` as TableProps<T,K>["type"]) , color}}  />
                </div>
                <div className={styles.fixedTableMobile} >
                    <Table {...{columns:{},type:(`${type}OnlyAction` as TableProps<T,K>["type"]), color}} />
                </div>
            </div>
            <Footer {...{color}}/>
        </>
    )

};

export default TableMobile;

