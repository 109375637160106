import React from 'react'
import styles from './LabelsProgressBar.module.css'
import Label from '../UI/Label'
import ProgressBar from '../UI/ProgressBar'
interface LabelProgressBarProps {
    type: 'horizontal' | 'vertical'
    label1: string
    label1className: string
    label2: string
    label2className: string
    progressBarStatus?: number
}

const LabelsProgressBar = (props: LabelProgressBarProps) => {
    const input = () => {
        switch (props.type) {
            case "vertical": {
                return (<>
                    <div className={styles.verticalContainer}>
                        <Label text={props.label1} className={props.label1className} />
                        <Label text={props.label2} className={props.label2className} />
                        {props.progressBarStatus && <ProgressBar completed={props.progressBarStatus} containerColor={styles.verticalProgressbarContainer} fillerColor={styles.verticalProgressbarFiller} />}
                    </div>
                </>)
            }
            case "horizontal": {
                const progressBarHeight = `height:${props.progressBarStatus}%;background: #148AAD;border-radius: 0px 5px`;
                return (<>
                    <div className={styles.horizontalContainer}>
                        {/* {props.progressBarStatus && <ProgressBar completed={100} containerColor={styles.horizontalProgressbarContainer} fillerColor={styles.horizontalProgressbarFiller} />} */}
                        {props.progressBarStatus && <>
                            <div className={styles.horizontalProgressbarContainer}>
                                <div className={styles.horizontalProgressbarFiller} style={{height: 100-props.progressBarStatus}}>

                                </div>
                            </div>
                        </>
                        }
                        <div className={styles.horizontalLabelsContainer}>
                            <Label text={props.label1} className={props.label1className} />
                            <Label text={props.label2} className={props.label2className} />

                        </div>

                    </div>
                </>)
            }
            default: {
                return <></>
            }
        }
    }

    return (
        <>{input()}</>
    )
}

export default LabelsProgressBar
