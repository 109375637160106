/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageNotificationPageOutputDTO } from '../models/PageNotificationPageOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomNotificationsResourceService {

    /**
     * Update Notification
     * Update the notification with the notification ID specified in input
     * @param id The ID of notification to filter by
     * @returns boolean OK
     * @throws ApiError
     */
    public static updateNotificationsUnread(
        id: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/custom/notification/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves All notifications for a user by ID
     * Create a paginated page, and return the result page containing all notification by user id
     * @param pageNo The page number for pagination
     * @param pageSize The number of items page for pagination
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageNotificationPageOutputDTO OK
     * @throws ApiError
     */
    public static getAllNotificationsByUser(
        pageNo?: number,
        pageSize?: number,
        sortBy?: string,
        sortDir?: string,
    ): CancelablePromise<PageNotificationPageOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/notificationsByUser',
            query: {
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
