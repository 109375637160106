import React from "react";
import {PageableObject, SitesPageOutputDTO, SortObject} from "@/services/openapi";

export type PagingType = {
    pageNumber: number;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
    pageSize: number;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    sortBy?: string;
    setSortBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    sortOrder?: "ASC" | "DESC";
    setSortOrder: React.Dispatch<React.SetStateAction<"ASC" | "DESC" | undefined>>;
    totalPages: number;
    setTotalPages: React.Dispatch<React.SetStateAction<number>>;
    totalElements?: number;
    setTotalElements: React.Dispatch<React.SetStateAction<number | undefined>>;
    prevOnClick: () => void;
    nextOnClick: () => void;
    firstOnClick: () => void;
    lastOnClick: () => void;
    remoteGet: {
        data?: any;
        loading: boolean;
        fetch: () => void;
        error?: string;
    },
    setUpdateFetch: React.Dispatch<React.SetStateAction<boolean>>;
}

export type PageRequestType = Pick<PagingType, "pageNumber" | "pageSize" | "sortBy" | "sortOrder">;

export type PageResponseType<T> = {
    totalPages?: number;
    totalElements?: number;
    content?: Array<T>;
    number?: number;
    sort?: SortObject;
    pageable?: PageableObject;
    first?: boolean;
    last?: boolean;
    numberOfElements?: number;
    empty?: boolean;
}

export const defaultPaging: PagingType = {
    pageNumber: 0,
    setPageNumber: () => {
    },
    pageSize: 10,
    setPageSize: () => {
    },
    sortBy: undefined,
    setSortBy: () => {
    },
    sortOrder: undefined,
    setSortOrder: () => {
    },
    totalPages: 1,
    setTotalPages: () => {
    },
    totalElements: undefined,
    setTotalElements: () => {
    },
    prevOnClick: () => {
    },
    nextOnClick: () => {
    },
    firstOnClick: () => {
    },
    lastOnClick: () => {
    },
    remoteGet: {
        loading: false,
        fetch: () => {
        }
    },
    setUpdateFetch: () => {
    }
}
