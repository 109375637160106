import React from 'react'
import styles from './ReportAssists.module.css'
import LabelsProgressBar from '../LabelsProgressBar'
import { t } from 'i18next'

interface ReportAssistsProps {
    data: AssistsData[]
}

interface AssistsData {
    id: number
    name: string
    value: number
}


const ReportAssists = (props: ReportAssistsProps) => {

    let assists = [<></>];

    props.data.map(value => {
        assists.push(
            <LabelsProgressBar type={'horizontal'} label1={"" + value.value + "%"} label1className={styles.titleLabelsProgressbar} label2={t(`assists.reasons.${value.name}`)} label2className={styles.subtitleLabelsProgressbar} progressBarStatus={value.value} />
        )
    });

    return (
        <div className={styles.boxContainer}>
            <div className={styles.boxSubContainer}>

                {assists}

            
            </div>
        </div>
    )
}

export default ReportAssists
