/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssistDevicePageOutputDTO } from '../models/AssistDevicePageOutputDTO';
import type { NotificationDeviceDetailOutputDTO } from '../models/NotificationDeviceDetailOutputDTO';
import type { PageAssistDevicePageOutputDTO } from '../models/PageAssistDevicePageOutputDTO';
import type { PageUsageAssistPageOutputDTO } from '../models/PageUsageAssistPageOutputDTO';
import type { PageUsageDevicePageOutputDTO } from '../models/PageUsageDevicePageOutputDTO';
import type { UsageAssistPageOutputDTO } from '../models/UsageAssistPageOutputDTO';
import type { UsageDevicePageOutputDTO } from '../models/UsageDevicePageOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomDeviceDetailResourceService {

    /**
     * Retrieves the device detail usage table paginated
     * Create a device page with input data and, paginate it, and return the result page of device detail usage table
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param deviceId The ID of device to filter by
     * @param siteId The ID of the site to filter by
     * @param pageNo The page number for pagination
     * @param pageSize The number of items page for pagination
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageUsageDevicePageOutputDTO OK
     * @throws ApiError
     */
    public static getUsageDevicePage(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        deviceId: number,
        siteId?: number,
        pageNo?: number,
        pageSize?: number,
        sortBy?: string,
        sortDir?: string,
    ): CancelablePromise<PageUsageDevicePageOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-detail-usage-table',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'deviceId': deviceId,
                'siteId': siteId,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the device detail usage table without paginated
     * Create a device page with input data without paginate it, and return the mapped page of device detail usage table
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param deviceId The ID of device to filter by
     * @param siteId The ID of the site to filter by
     * @returns UsageDevicePageOutputDTO OK
     * @throws ApiError
     */
    public static getUsageDevicePageUnpaged(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        deviceId: number,
        siteId?: number,
    ): CancelablePromise<Array<UsageDevicePageOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-detail-usage-table-unpaged',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'deviceId': deviceId,
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the device detail routes table paginated
     * Create a device page with input data, paginate it, and return the result page of device detail routes table
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param deviceId The ID of device to filter by
     * @param siteId The ID of the site to filter by
     * @param pageNo The page number for pagination
     * @param pageSize The number of items page for pagination
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageUsageAssistPageOutputDTO OK
     * @throws ApiError
     */
    public static getRoutesDevicePage(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        deviceId?: number,
        siteId?: number,
        pageNo?: number,
        pageSize?: number,
        sortBy?: string,
        sortDir?: string,
    ): CancelablePromise<PageUsageAssistPageOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-detail-routes-table',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'deviceId': deviceId,
                'siteId': siteId,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the device detail routes table without paginated
     * Create a device page with input data without paginate it, and return the mapped page of device detail routes table
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param deviceId The ID of device to filter by
     * @param siteId The ID of the site to filter by
     * @returns UsageAssistPageOutputDTO OK
     * @throws ApiError
     */
    public static getRoutesDevicePageUnpaged(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        deviceId?: number,
        siteId?: number,
    ): CancelablePromise<Array<UsageAssistPageOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-detail-routes-table-unpaged',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'deviceId': deviceId,
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the device detail notification
     * Create a device page with input data, paginate it, and return the result page of device detail notification table
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param deviceId The ID of device to filter by
     * @param siteId The ID of the site to filter by
     * @returns NotificationDeviceDetailOutputDTO OK
     * @throws ApiError
     */
    public static getNotificationDevicePage(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        deviceId?: number,
        siteId?: number,
    ): CancelablePromise<Array<NotificationDeviceDetailOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-detail-notification',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'deviceId': deviceId,
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves device detail assist table paginated
     * Create a device page with input data, paginate it, and return the resulting page of device detail assist table
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param deviceId The ID of device to filter by
     * @param siteId The ID of the site to filter by
     * @param pageNo The page number for pagination
     * @param pageSize The number of items page for pagination
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageAssistDevicePageOutputDTO OK
     * @throws ApiError
     */
    public static getAssistDevicePage(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        deviceId?: number,
        siteId?: number,
        pageNo?: number,
        pageSize?: number,
        sortBy?: string,
        sortDir?: string,
    ): CancelablePromise<PageAssistDevicePageOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-detail-assist-table',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'deviceId': deviceId,
                'siteId': siteId,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves device detail assist table without paginated
     * Create a device page with input data without paginate it, and return the mapped page of device detail assist table
     * @param dateStart The Start date to filter by
     * @param dateEnd The End date to filter by
     * @param timezone The time zone to filter by
     * @param deviceId The ID of device to filter by
     * @param siteId The ID of the site to filter by
     * @returns AssistDevicePageOutputDTO OK
     * @throws ApiError
     */
    public static getAssistDevicePageUnpaged(
        dateStart: string,
        dateEnd: string,
        timezone: string,
        deviceId?: number,
        siteId?: number,
    ): CancelablePromise<Array<AssistDevicePageOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-detail-assist-table-unpaged',
            query: {
                'dateStart': dateStart,
                'dateEnd': dateEnd,
                'timezone': timezone,
                'deviceId': deviceId,
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
