// App.tsx

import React, {useContext, useEffect} from 'react';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import Layout from '@Layout/index';
import {AuthContextProvider} from '@Components/LoginForm/auth-context';
import {WindowSizeProvider} from './hooks';
import {FilterDateProvider} from './hooks/FilterDateContext/FilterDateProvider';
import {PositionContext, PositionProvider} from './contexts/PositionContext';
import moment from 'moment';
import {OpenAPI} from './services/openapi';

function App() {
  const {country, continent, ipAddress} = useContext(PositionContext);

  OpenAPI.BASE = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8080';

  const updateOpenAPI = (key: string, value: string) => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      [key]: value
    }
  }

  useEffect(() => {
    if (country) {
      updateOpenAPI('X_Tennant_Country', country);
    }
    if (continent) {
      updateOpenAPI('X_Tennant_Continent', continent);
    }
    if (ipAddress) {
      updateOpenAPI('X_Tennant_Client_IP', ipAddress);
    }
  }, [country, continent, ipAddress]);

  const timezone = moment.tz.guess();
  OpenAPI.HEADERS = {
    ...OpenAPI.HEADERS,
    'X_Tennant_Timezone': timezone,
  };

  return (
      <div className="App">
        <WindowSizeProvider>
          <FilterDateProvider>
            <BrowserRouter>
              <AuthContextProvider>
                <Layout/>
              </AuthContextProvider>
            </BrowserRouter>
          </FilterDateProvider>
        </WindowSizeProvider>
      </div>
  );
}

export default App;
