import moment from "moment-timezone";
import {TFunction} from "i18next";

export const formatDate = (date: string, format?: string) => {
    if (!date) {
        return '';
    }

    if (/^\d{1,2}\s+\w{3}$/.test(date) || /^\d{2}\s+\w{3}$/.test(date)) {
        return date;
    }

    const utcTime = date?.substring(date.indexOf('T') + 1, date.length) ?? '';

    const t = moment.tz(date, moment.tz.guess());
    const formattedDate = t.format('YYYY-MM-DDTHH:mm:ss');

    const time = formattedDate?.substring(formattedDate.indexOf('T') + 1, formattedDate.length) ?? '';
    
    if (!format) {
        if (time === '00:00:00' || utcTime === '00:00:00Z') {
            format = 'DD/MM/YYYY'
        } else {
            format = 'DD/MM/YYYY HH:mm:ss'
        }
    }

    return t.format(format);
}

export const translateAssistNotification = (t: TFunction<'translation', undefined>, benchmarkType: string, status: string) => {
    const translationKey = `notifications.${status}.${benchmarkType}`;
    return t(translationKey);
}

export const formDateToTimestamp = (formDate: string, endDate?: boolean) => {
    const tmpMoment = moment(formDate);
    return moment()
        .year(tmpMoment.year())
        .month(tmpMoment.month())
        .date(tmpMoment.date())
        .set({
            hour: endDate ? 23 : 0,
            minute: endDate ? 59 : 0,
            second: endDate ? 59 : 0,
            millisecond: endDate ? 999 : 0
        }).utc().format();
}
