import React, {createContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {continentMapper} from '@Assets/const/countryCodeToContinent';

interface Coordinates {
  latitude: number;
  longitude: number;
}

export const PositionContext = createContext<{
  country: string | null;
  continent: string | null;
  userPosition: Coordinates | null;
  positionConsent: boolean;
  ipAddress: string | null;
}>({
  country: null,
  continent: null,
  userPosition: null,
  positionConsent: false,
  ipAddress: null
});

interface PositionProviderProps {
  children: React.ReactNode;
}

export const PositionProvider: React.FC<PositionProviderProps> = ({children}) => {
  const {t} = useTranslation();
  const [userPosition, setUserPosition] = useState<Coordinates | null>(
      JSON.parse(localStorage.getItem('userPosition') || 'null')
  );
  const [country, setCountry] = useState<string | null>(null);
  const [continent, setContinent] = useState<string | null>(null);
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const storagePositionConsent = localStorage.getItem('positionConsent');

  const [positionDenied, setPositionDenied] = useState<boolean>(storagePositionConsent !== 'true');

  const calculateExpirationTime = () => {
    const expirationTime = new Date();
    expirationTime.setHours(expirationTime.getHours() + 24);
    return expirationTime;
  };

  const isPositionExpired = (expirationTime: Date): boolean => {
    const now = new Date();
    const tenMinutesBeforeExpiration = new Date(expirationTime);
    tenMinutesBeforeExpiration.setMinutes(tenMinutesBeforeExpiration.getMinutes() - 10);
    return now > tenMinutesBeforeExpiration;
  };


  useEffect(() => {
    const expirationTime = JSON.parse(localStorage.getItem('positionExpirationTime') || 'null');
    if (expirationTime) {
      if (isPositionExpired(expirationTime)) {
        localStorage.removeItem('positionExpirationTime');
        localStorage.removeItem('userPosition');
        setUserPosition(null);
      }
    } else {
      localStorage.removeItem('userPosition');
      setUserPosition(null);
    }

    const fetchIp = async () => {
      return fetch('https://api.ipify.org');
    }

    fetchIp()
        .then(resp => resp.text())
        .then(ip => {
          if (ip) {
            setIpAddress(ip);
          }
        })
        .catch(error => console.error(error));
  }, []);

  function waitForGoogleMaps(callback: () => void, interval = 100) {
    if (window.google && window.google?.maps) {
      callback();
    } else {
      setTimeout(() => {
        waitForGoogleMaps(callback, interval);
      }, interval);
    }
  }

  const requestUserLocation = () => {
    if (navigator.geolocation) {
      let consent;
      if (positionDenied) {
        consent = window.confirm(t('maps.sharePosition'));
      } else {
        consent = true;
      }
      if (consent) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              setUserPosition({latitude: position.coords.latitude, longitude: position.coords.longitude});
              localStorage.setItem('positionConsent', 'true');
              setPositionDenied(false);
              localStorage.setItem('userPosition', JSON.stringify({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              }));
              localStorage.setItem('positionExpirationTime', JSON.stringify(calculateExpirationTime()));
            },
            (error) => {
              console.error('Error sharing position:', error);
            }
        );
      } else {
        console.log('User refused to share position.');
        setPositionDenied(true);
        localStorage.setItem('positionConsent', 'false');
      }
      return consent;
    }
  };

  const updateCountryAndContinent = () => {
    if (userPosition) {

      const latlng = {
        lat: userPosition.latitude,
        lng: userPosition.longitude,
      };

      const geocoder = new window.google.maps.Geocoder();
      geocoder
          .geocode({location: latlng, language: 'en'})
          .then((response) => {
            if (response.results[0]) {
              const countryComponent = response.results[0].address_components.find((component: any) =>
                  component.types.includes('country')
              );
              if (countryComponent) {
                const countryCode = countryComponent.short_name;
                const continent = continentMapper[countryCode];
                setCountry(countryComponent.long_name);
                setContinent(continent);
              }
            }
          })
          .catch((error) => {
            console.error('Error requesting data using geoCoding API: ', error);
          });
    } else {
      requestUserLocation();
    }
  }

  useEffect(() => {
    if (userPosition && (!country || !continent)) {
      waitForGoogleMaps(updateCountryAndContinent);
    }
  }, [userPosition, country, continent]);

  useEffect(() => {
    const tick = () => {
      if (positionDenied) {
        const result = requestUserLocation();
        if (!result) {
          setTimeout(tick, 7000);
        }
      }
    }

    let timer: NodeJS.Timeout | undefined;
    if (positionDenied) {
      timer = setTimeout(tick, 0);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [positionDenied]);


  useEffect(() => {
    if (userPosition) {
      waitForGoogleMaps(updateCountryAndContinent);
    } else if (!positionDenied) {
      requestUserLocation();
    }
  }, [userPosition]);

  return (
      <PositionContext.Provider value={{userPosition, country, continent, positionConsent: !positionDenied, ipAddress}}>
        {children}
      </PositionContext.Provider>
  );
};
