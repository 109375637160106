import {useState} from "react";
import {FilterDateContext} from "./useFilterDateContext";
import moment from "moment-timezone";

interface FilterDateProviderProps {
    children: JSX.Element | undefined
}

export const FilterDateProvider = ({children}: FilterDateProviderProps) => {


    const [date1, setDate1] = useState(moment().subtract(8, 'days').format('YYYY-MM-DD'));

    const [date2, setDate2] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
    const timezone = moment.tz.guess();

    return <FilterDateContext.Provider value={{
        date1, setDate1,
        date2, setDate2,
        timezone
    }}>
        {children}
    </FilterDateContext.Provider>
}
