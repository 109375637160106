import Cell from './Cell';
import styles from './Table.module.css';

import Img from '../Img';
import UsageBar from '@/components/UsageBar';
import { useNavigate, useParams } from 'react-router-dom';
import {
	CustomNotificationsResourceService,
	UsagePeriodOutputDTO
} from '@/services/openapi';
import { useWindowContext } from '@/hooks';

type RowProps<
	T extends Record<string, any>,
	K extends Partial<Record<Extract<keyof T, string>, string>>
> = {
	columns: K;
	data: T;
	index: number;
	fullCol?: boolean;
	hidden?: boolean;
	onClick?: () => void;
	style?: string;
	icon?: string;
	type:
		| 'sitesRanking'
		| 'amrRanking'
		| 'conventionalRanking'
		| 'simpleNoAction'
		| 'simpleAction'
		| 'simpleMap'
		| 'collapsed'
		| 'notification'
		| 'normal'
		| 'amrRankingOnlyAction'
		| 'amrRankingWithoutAction'
		| 'sitesRankingOnlyAction'
		| 'sitesRankingWithoutAction';
	color?: 'green' | 'orange' | 'blue';
};

const Row = <
	T extends Record<string, any>,
	K extends Partial<Record<Extract<keyof T, string>, string>>
>({
	columns,
	data,
	index,
	fullCol,
	hidden,
	onClick,
	style,
	icon,
	type,
	color
}: RowProps<T, K>) => {
	const navigate = useNavigate();
	const { isMobile } = useWindowContext();
	const keys = Object.keys(columns);
	let row: JSX.Element;
	const { origin } = useParams();

	if (fullCol) {
		row = (
			<td colSpan={keys.length + 1}>
				<UsageBar isOpen={fullCol} data={data as any as UsagePeriodOutputDTO[]} />
			</td>
		);
	} else {
		row = (
			<>
				{keys.map((column, indexCol) => {
					if (typeof data[column] !== 'object') {
						return (
							<Cell
								key={color + ('' + indexCol)}
								id={column}
								rowNumber={index + 1}
								data={data[column]}
								index={indexCol}
								type={type}
								color={color}
								rowData={data}
							/>
						);
					} else {
						if (column === 'period') {
							return (
								<td className={styles.td}>
									<div
										className={
											isMobile ? styles.iconContainerUsageBar : styles.iconContainer
										}
									>
										<UsageBar isOpen={fullCol ?? false} data={data.period} />
										<Img
											containerStyle={styles.icon}
											className={''}
											alt={''}
											src={icon}
											onClick={onClick}
										/>
									</div>
								</td>
							);
						} else {
							return (
								<Cell
									key={color + ('' + indexCol)}
									id={column}
									rowNumber={index + 1}
									data={''}
									index={indexCol}
									type={type}
									color={color}
								/>
							);
						}
					}
				})}
			</>
		);
	}

	if (hidden) {
		return <></>;
	}
	//const style = index % 2 === 0 ? styles.even : styles.odd;

	if (fullCol) {
		//Unica cella con colonne unite
		return <tr className={styles.tr}>{row}</tr>;
	} else {
		// if (type == "collapsed") {
		//     //return <>ciao</>;
		//     return <tr className={styles.tr} >{row}<td className={styles.td}><div className={styles.iconContainer}><UsageBar isOpen={fullCol ?? false} data={data.usagePeriod} /><Img containerStyle={styles.icon} className={""} alt={''} src={icon} onClick={onClick} /></div></td></tr>;
		//     //return <tr className={styles.tr} >{row}<td className={styles.td}><div className={styles.iconContainer}><Img containerStyle={styles.icon} className={""} alt={''} src={icon} onClick={onClick}/></div></td></tr>;
		// } else if (type == "sitesRanking") {
		//     return <tr className={styles.tr} >{row}<td className={styles.td}><div className={styles.iconContainer}><Img containerStyle={styles.icon} className={""} alt={''} src={icon} onClick={() =>  navigate("/Site?id=" + data["sitesId"])} /></div></td></tr>;
		// } else {
		//     return <tr className={styles.tr} >{row}<td className={styles.td}><div className={styles.iconContainer}><Img containerStyle={styles.icon} className={""} alt={''} src={icon} onClick={onClick} /></div></td></tr>;
		// }

		switch (type) {
			case 'collapsed': {
				return <tr className={styles.tr}>{row}</tr>;
			}
			case 'sitesRankingOnlyAction': {
				return (
					<tr className={styles.trAction}>
						<td className={styles.tdAction}>
							<div className={styles.iconContainer}>
								<Img
									containerStyle={styles.icon}
									className={''}
									alt={''}
									src={icon}
									onClick={() => navigate(`/Site/${origin}?id=${data['sitesId']}`)}
								/>
							</div>
						</td>
					</tr>
				);
			}
			case 'sitesRankingWithoutAction':
			case 'sitesRanking': {
				return (
					<tr
						className={
							data.autonomusUsage.startsWith('00:00')
								? [styles.tr, styles.trBadAutoUsage].join(' ')
								: styles.tr
						}
					>
						{row}
						{type === 'sitesRanking' && (
							<td className={styles.td}>
								<div className={styles.iconContainer}>
									<Img
										containerStyle={styles.icon}
										className={''}
										alt={''}
										src={icon}
										onClick={() => navigate(`/Site/${origin}?id=${data['sitesId']}`)}
									/>
								</div>
							</td>
						)}
					</tr>
				);
			}
			case 'amrRankingOnlyAction': {
				return (
					<tr className={styles.trAction}>
						<td className={styles.tdAction}>
							<div className={styles.iconContainer}>
								<Img
									containerStyle={styles.icon}
									className={''}
									alt={''}
									src={icon}
									onClick={() => navigate(`/Machine/${origin}?id=${data['deviceId']}`)}
								/>
							</div>
						</td>
					</tr>
				);
			}
			case 'amrRankingWithoutAction':
			case 'amrRanking': {
				return (
					<tr
						className={
							data.autonomusUsage.startsWith('00:00')
								? [styles.tr, styles.trBadAutoUsage].join(' ')
								: styles.tr
						}
					>
						{row}
						{type === 'amrRanking' && (
							<td className={styles.td}>
								<div className={styles.iconContainer}>
									<Img
										containerStyle={styles.icon}
										className={''}
										alt={''}
										src={icon}
										onClick={() => navigate(`/Machine/${origin}?id=${data['deviceId']}`)}
									/>
								</div>
							</td>
						)}
					</tr>
				);
			}
			case 'conventionalRanking': {
				return (
					<tr className={styles.tr}>
						{row}
						{type === 'conventionalRanking' && (
							<td className={styles.td}>
								<div className={styles.iconContainer}>
									<Img
										containerStyle={styles.icon}
										className={''}
										alt={''}
										src={icon}
										onClick={() => navigate(`/Machine/${origin}?id=${data['deviceId']}`)}
									/>
								</div>
							</td>
						)}
					</tr>
				);
			}
			case 'notification': {
				if (data['unread'] == false) {
					return (
						<tr className={styles.tr} style={{ opacity: 0.5 }}>
							{row}
							<td className={styles.td}>
								<div className={styles.iconContainer}>
									<Img
										containerStyle={styles.icon}
										className={''}
										alt={''}
										src={icon}
										onClick={() => navigate(`/Machine/${origin}?id=${data['deviceId']}`)}
									/>
								</div>
							</td>
						</tr>
					);
				} else {
					return (
						<tr className={styles.tr}>
							{row}
							<td className={styles.td}>
								<div className={styles.iconContainer}>
									<Img
										containerStyle={styles.icon}
										className={''}
										alt={''}
										src={icon}
										onClick={() => {
											CustomNotificationsResourceService.updateNotificationsUnread(
												data['id']
											);
											navigate(`/Machine/${origin}?id=${data['deviceId']}`);
										}}
									/>
								</div>
							</td>
						</tr>
					);
				}
			}
			case 'simpleNoAction': {
				return <tr className={styles.tr}>{row}</tr>;
			}
			default: {
				return (
					<tr className={styles.tr}>
						{row}
						<td className={styles.td}>
							<div className={styles.iconContainer}>
								<Img
									containerStyle={styles.icon}
									className={''}
									alt={''}
									src={icon}
									onClick={onClick}
								/>
							</div>
						</td>
					</tr>
				);
			}
		}
	}
};

export default Row;
