import { Bar, BarChart, CartesianGrid, Cell, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import styles from './BarChart.module.css';
import React from 'react';


export interface BarChartProps {
    
    data: DataProps[],
    widthRC: string,
    heightRC: string,
    widthBarC: number,
    heightBarC: number,
    barSize: number,
    dataKey: string,
    top: number,
    right: number,
    left: number,
    bottom: number,
    color?: string
    vertical: boolean,
    labelX: string,
    labelY: string,
    maxValue: number,
    fontSizeYAxis?: number,
    stacked?: boolean,
    stackedLabel?: string
}

export interface DataProps {
    
    name: string,
    uv: number,
    amt?: number,
    
}


const Barchart = (props: BarChartProps) => {
    const CustomTooltip = ({ payload, label, active }: TooltipProps<string, string>) => {
        if (active) {
            return (<div className={styles.customTooltip}>
                <p className={styles.labelX}><span className={styles.title}>{`${props.labelX}`}</span>{`: ${label}`}</p>
                <p className={styles.labelY}>
                    <span className={styles.title}>
                        {`${props.labelY}`}
                    </span>
                    {`: ${payload ? payload[0].value : ''}`}
                </p>
                {props.stacked && (<p className={styles.labelY}>
                    <span className={styles.title}>
                        {`${props.stackedLabel}`}
                    </span>
                    {`: ${payload ? payload[1].value : ''}`}
                </p>)}
            </div>);
        }
        return null;
    };
    
    const colors = ['#7ED6FB', '#20ACD9', '#09556F', '#06152B', '#7ED6FB', '#20ACD9', '#09556F', '#06152B', '#7ED6FB', '#20ACD9', '#09556F', '#06152B'];
    return (
        <BarChart
            width={props.widthBarC}
            height={props.heightBarC}
            data={props.data}
            margin={{
                top: props.top,
                right: props.right,
                left: props.left,
                bottom: props.bottom
            }}
            layout={props.vertical ? 'vertical' : 'horizontal'}
        >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <CartesianGrid width={props.widthBarC * 70 / 100} horizontal={true} vertical={false} opacity={0.2} />
            
            {props.vertical ?
                <>
                    <YAxis width={props.widthBarC * 30 / 100} dataKey={props.dataKey}
                           style={{ wordBreak: 'break-word' }}
                           fontSize={props.fontSizeYAxis} type="category" />
                    <XAxis hide type="number" domain={[0, props.maxValue]} />
                </> :
                <>
                    <YAxis type="number" domain={[0, props.maxValue]} />
                    <XAxis dataKey={props.dataKey} hide />
                </>
            }
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            <Bar dataKey="uv" stackId="a" barSize={props.barSize}>
                {props.data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                
                ))}
            
            </Bar>
            {props.stacked ?
                <Bar dataKey="amt" stackId="a" barSize={props.barSize}>
                    {props.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index + 3 % 20]} />
                    
                    ))}
                </Bar> : <></>
            }
        
        </BarChart>
    );
    
};

export default Barchart;
