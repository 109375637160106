import React, {useEffect, useState} from "react";
import styles from "./SelectBox.module.css";
import Select, {SelectInstance} from "react-select";
import {
  ActionMeta,
  OnChangeValue,
} from "react-select/dist/declarations/src/types";
import {useTranslation} from "react-i18next";

export interface SelectDataProps {
  value: string;
  label: string;
}

export interface SelectProps {
  name: string;
  firstOption?: string;
  selectData: SelectDataProps[];
  style?: string;
  ref?: React.RefObject<SelectInstance<SelectDataProps>>;
  valueSelected?: SelectDataProps;
  multiValueSelected?: SelectDataProps[];
  isMulti?: boolean;
  isUserTriggered?: boolean;
  onChange?: (
      newValue: OnChangeValue<SelectDataProps, boolean>,
      actionMeta: ActionMeta<SelectDataProps>,
      isUserTriggered: boolean
  ) => void;
}

const SelectBox = (props: SelectProps) => {
  const {t} = useTranslation();
  const [value, setValue] = useState<SelectDataProps>();
  const [multiValue, setMultiValue] = useState<SelectDataProps[]>([]);

  if (props.firstOption &&
      props.selectData.filter((d) => d.value === props.firstOption).length === 0
  ) {
    if (props.firstOption === "All") {
      props.selectData.splice(0, 0, {
        value: props.firstOption,
        label: t("filters.default"),
      });
    } else {
      props.selectData.splice(0, 0, {
        value: props.firstOption,
        label: props.firstOption,
      });
    }
  }

  useEffect(() => {
    // Gestione select MULTIPLA
    if (props.isMulti) {
      let toChangeValue: SelectDataProps[] = [];
      if (
          props.isUserTriggered &&
          props.multiValueSelected &&
          props.multiValueSelected.length > 0
      ) {
        toChangeValue = props.multiValueSelected;
      } else if (!props.isUserTriggered) {
        if (props.selectData.length === 1) {
          toChangeValue = props.selectData;
        } else if (props.multiValueSelected!.length === 0) {
          toChangeValue = [];
        } else {
          toChangeValue = props.multiValueSelected!;
        }
      }

      let actionMulti:
          | "select-option"
          | "deselect-option"
          | "remove-value"
          | "clear";

      if (toChangeValue.length > multiValue.length) {
        actionMulti = "select-option";
      } else if (toChangeValue.length < multiValue.length) {
        actionMulti = "remove-value";
      }

      setMultiValue(toChangeValue);

      if (
          !props.isUserTriggered &&
          props.onChange &&
          toChangeValue.length > 0 &&
          multiValue.every(
              (value, index) =>
                  props.multiValueSelected &&
                  props.multiValueSelected[index] &&
                  value.value !== props.multiValueSelected[index].value
          )
      ) {
        toChangeValue.forEach((val, i) => {
          let actionMeta: ActionMeta<SelectDataProps>;
          switch (actionMulti) {
            case "select-option":
              actionMeta = {
                action: actionMulti,
                option: toChangeValue[i],
              };
              break;
            case "deselect-option":
              actionMeta = {
                action: actionMulti,
                option: toChangeValue[i],
              };
              break;
            case "remove-value":
              actionMeta = {
                action: actionMulti,
                removedValue: toChangeValue[i],
              };
              break;
            default:
              actionMeta = {
                action: actionMulti,
                removedValues: toChangeValue,
              };
          }

          props.onChange && props.onChange(toChangeValue, actionMeta, false);
        });
      }
    }

    // Gestione select SINGOLA
    else {
      let toChangeValue: SelectDataProps = props.firstOption && props.firstOption === 'All'? {
        value: "All",
        label: t("filters.default"),
      } : props.selectData[0];

      if (props.valueSelected && props.valueSelected.value !== "All" && props.valueSelected.value !== "") {
        toChangeValue = props.valueSelected;
      } else if (!props.isUserTriggered && props.selectData.length === 2) {
        toChangeValue = props.selectData[1];
      } 
      setValue(toChangeValue);

      if (
          !props.isUserTriggered &&
          props.onChange &&
          (value?.value !== props.valueSelected?.value || toChangeValue.value !== value?.value)
      ) {
        const actionMeta: ActionMeta<SelectDataProps> = {
          action: "select-option",
          option: toChangeValue,
        };
        props.onChange(toChangeValue, actionMeta, false);
      }
    }
  }, [props.isUserTriggered, props.multiValueSelected, props.valueSelected]);

  return (
      <>
        <Select
            className={props.style ? props.style : styles.Select}
            name={props.name}
            ref={props.ref}
            options={props.selectData}
            onChange={(options, actionMeta) =>
                props.onChange!(options, actionMeta, true)
            }
            isSearchable={true}
            defaultValue={props.firstOption ? props.selectData[0] : null}
            value={props.isMulti ? multiValue : value}
            isMulti={props.isMulti ?? false}
        />
      </>
  );
};

export default SelectBox;
