/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageMachineOutputDTO } from '../models/PageMachineOutputDTO';
import type { SiteMachineOutputDTO } from '../models/SiteMachineOutputDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomAmrMachinePageResourceService {

    /**
     * Retrieve information about the amrMachine devices in one site
     * @returns SiteMachineOutputDTO OK
     * @throws ApiError
     */
    public static amrMachinePageCountDevice(): CancelablePromise<Array<SiteMachineOutputDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/amrMachine-site-count',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieve a paginated list of AMR machines
     * Retrieve a paginated list of AMR machines based on specified filter and pagination options
     * @param siteId The ID of the site to filter by
     * @param machineModel  The model of the machine to filter by
     * @param pageNo  The page number for pagination
     * @param pageSize The number of items per page for pagination.
     * @param sortBy The field to sort the results by
     * @param sortDir The sorting direction
     * @returns PageMachineOutputDTO OK
     * @throws ApiError
     */
    public static amrMachinePageTable(
        siteId?: number,
        machineModel?: string,
        pageNo?: number,
        pageSize?: number,
        sortBy?: string,
        sortDir?: string,
    ): CancelablePromise<PageMachineOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/amrMachine-page-table',
            query: {
                'siteId': siteId,
                'machineModel': machineModel,
                'pageNo': pageNo,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
