import React, { useState } from 'react'
import styles from './MachineDetails.module.css'
import StandardMachineIcon from '../../assets/images/standardMachine.svg'
import BestPerformingIcon from '../../assets/images/bestPerforming.svg'
import Button from '@/components/UI/Button'
import UsageBar from '@/components/UsageBar'
import Table from '../UI/Table/Table'
import MachineUsageHead from '../MachineUsageHead'
import Legend from '../Legend'
import MachineUsage from '../MachineUsage/MachineUsage'
import { useTranslation } from "react-i18next";
import { SiteDetailPageOutputDTO } from "@/services/openapi";
import { useWindowContext } from '@/hooks'


interface MachineDetailsProps {
    id: number
    type: string
    name: string
    // usage: Array<UsageProps>
    auto?: string;
    manual?: string;
    training?: string;
    autonomus?: number
    details: SiteDetailPageOutputDTO[]
    detailsLoading?: boolean
}

// interface UsageProps {
//     date: string
//     auto: string
//     manual: string
//     training: string
//     autonomus: number
//     usagePeriod: Array<UsagePeriodProps>
// }

// export interface UsagePeriodProps {
//     inizio: string
//     fine: string
//     startInMinutes: number
//     endInMinutes: number
// }

const MachineDetails = (props: MachineDetailsProps) => {
    const { t } = useTranslation();
    const [show, setShow] = useState<Boolean>(false);
    const { size, isMobile } = useWindowContext()


    const handleShowDetails = () => {
        setShow(!show);
    }

    const colonne = {
        date: t('columns.machineDetails.date'),
        autoUsage: t('columns.machineDetails.auto'),
        manualUsage: t('columns.machineDetails.manual'),
        trainingUsage: t('columns.machineDetails.training'),
        autonomousUsagePerc: t('columns.machineDetails.autonomous'),
        period: t('columns.machineDetails.usagePeriod')
    }
    const colonneMobile = {
        date: t('columns.machineDetails.date'),
        period: t('columns.machineDetails.usagePeriod'),
        autoUsage: t('columns.machineDetails.auto'),
        manualUsage: t('columns.machineDetails.manual'),
        trainingUsage: t('columns.machineDetails.training'),
        autonomousUsagePerc: t('columns.machineDetails.autonomous'),
    }

    return (
        <div className={styles.machine_main_container}>
            <div className={styles.machine_container}>
                <div className={styles.machineDetailsHeader}>
                    {!isMobile && <MachineUsageHead containerClassname={styles.machine_header_container} img={StandardMachineIcon} imgClassname={styles.machineIcon} label1={props.type}
                        label1className={styles.machineDetailsTitle} label2={props.name}
                        label2className={styles.machineDetailsSubtitle} />}
                    {isMobile && <div className={styles.container_mobile_header}>
                        <MachineUsageHead containerClassname={styles.machine_header_container} img={StandardMachineIcon} imgClassname={styles.machineIcon} label1={props.type}
                            label1className={styles.machineDetailsTitle} label2={props.name}
                            label2className={styles.machineDetailsSubtitle} />
                        <div className={styles.container_btn}>
                            <Button onClick={handleShowDetails} className={show ? styles.active_btn : styles.nonActive_btn}
                                name={show ? '-' : '+'} />
                        </div>
                    </div>}
                    <MachineUsage classNameContainer={styles.machineUsage_container} titleText={'' + props.autonomus} topSmallTitle={"Autonomous Usage"}
                        progressBarStatus={props.autonomus} progressBarColor={'#148AAD'}
                        label1={'' + props.autonomus} label2={"vs prev 7 days"} img={BestPerformingIcon} />

                    <div className={styles.machineDetails_legend}>
                        <Legend 
                            containerClassname={styles.containerLegend}
                            legendStyle={styles.machineUsageAutonomus} label1={"Autonomous"}
                            label1className={styles.machineUsageLegendLabel}
                            label2={props.auto}
                            label2className={styles.machineUsageLegendLabel} />
                        <Legend 
                            containerClassname={styles.containerLegend}
                            legendStyle={styles.machineUsageTraining} label1={"Training"}
                            label1className={styles.machineUsageLegendLabel}
                            label2={props.training}
                            label2className={styles.machineUsageLegendLabel} />
                        <Legend 
                            containerClassname={styles.containerLegend}
                            legendStyle={styles.machineUsageManual} label1={"Manual"}
                            label1className={styles.machineUsageLegendLabel}
                            label2={props.manual}
                            label2className={styles.machineUsageLegendLabel} />
                    </div>

                    {!isMobile && <div className={styles.container_btn}>

                        <Button onClick={handleShowDetails} className={show ? styles.active_btn : styles.nonActive_btn}
                            name={show ? '-' : '+'} />
                    </div>}


                </div>
                {/* {show && <UsageBar />} */}
                {show && 
                    <div className={styles.tableContainer}>
                        <Table  columns={isMobile ? colonneMobile : colonne} data={props.details} dataLoading={props.detailsLoading}type={'collapsed'} />
                    </div>
                }
            </div>
        </div>
    )
}

export default MachineDetails
