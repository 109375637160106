import React, {useContext, useEffect} from 'react'
import styles from './News.module.css'
import Button from '@/components/UI/Button'
import {useTranslation} from 'react-i18next'
import Label from '@/components/UI/Label'
import LabelsProgressBar from '../../components/LabelsProgressBar'
import Box from '../../components/Box'
import Img from '../../components/UI/Img'
import img1 from '../../assets/images/newsImg1.png'
import newImgVerticalMobile from '../../assets/images/newImgVerticalMobile.png'
import newspaperIcon from '../../assets/images/newspaperIcon.svg'
import MainContext from '@/contexts/MainContext'
import { useWindowContext } from '@/hooks'

const News = () => {

    const titleCtx = useContext(MainContext);

    useEffect(() => {
        titleCtx.setTitle(<Label text={t('sidebar.news')} className={styles.titleLabel}/>)

    }, [])

    const {t} = useTranslation();
    const {isMobile} = useWindowContext()
    return (
        <div className={styles.main_container}>
            {!isMobile && <a href="https://sso.tennantco.com/authentication/login?sessionDataKey=5b28a0ee-e031-4d11-b4b7-1762ca6e9cc6&site=legacy"
               target="_blank" rel="noopener noreferrer" className={styles.link}>
                <Button name={t('news.goTo')} className={styles.headButton} onClick={() => {
                }}/>
            </a>}
            {isMobile && <Label text={t('sidebar.news')} className={styles.titlePageLabel}/> }
            <Label text={t('news.countryContact')} className={styles.titleLabel}/>
            <div className={isMobile?styles.verticalContainer:styles.horizontalContainer} style={{gap: "5%"}}>
                <Box className={styles.box_container} children={
                    <div className={styles.box_subContainer}>
                        <LabelsProgressBar type={'vertical'} label1={t('news.number')}
                                           label1className={styles.contactTitleLabel} label2={"+32 3 217 94 70"}
                                           label2className={styles.contactValueLabel}/>
                    </div>
                }/>

                <Box className={styles.box_container} children={
                    <div className={styles.box_subContainer}>
                        <LabelsProgressBar type={'vertical'} label1={t('news.email')}
                                           label1className={styles.contactTitleLabel} label2={"amr.data@tennantco.com"}
                                           label2className={styles.contactValueLabel}/>
                    </div>
                }/>


            </div>
            <Label text={t('news.promotions')} className={styles.titleLabel}/>

            {!isMobile && <div className={styles.carouselContainer}>
                <Img className={styles.img} alt={''} src={img1}/>
            </div>}

            {isMobile &&  <div className={styles.carouselContainer}>
                <Img className={styles.img} alt={''} src={newImgVerticalMobile}/>
                </div>
            }

            <Button name={t('news.digitalPromotion')} className={styles.footerBtn} iconStart={newspaperIcon}
                    onClick={() => {
                    }}/>
        </div>
    )
}

export default News
