/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NotificationPageOutputDTO = {
    id?: number;
    date?: string;
    deviceId?: number;
    status?: NotificationPageOutputDTO.status;
    rin?: string;
    siteName?: string;
    information?: string;
    unread?: boolean;
};

export namespace NotificationPageOutputDTO {

    export enum status {
        OK = 'OK',
        KO = 'KO',
        WARNING = 'WARNING',
    }


}

