import React from 'react'
import styles from './CustomerSurvey.module.css'
import Face1 from '../../assets/images/face1.svg'
import Face2 from '../../assets/images/face2.svg'
import Face3 from '../../assets/images/face3.svg'
import Face4 from '../../assets/images/face4.svg'
import Face5 from '../../assets/images/face5.svg'
import Img from '../UI/Img'
import Label from '../UI/Label'

const CustomerSurvey = () => {
    const faces = [
        {
            path: Face1,
            href: "https://info.tennantco.com/?e=313724&h=29EC564D20B2DD0&l=en&q1=1&as=1"
        },
        {
            path: Face2,
            href: "https://info.tennantco.com/?e=313724&h=29EC564D20B2DD0&l=en&q1=2&as=1"
        },
        {
            path: Face3,
            href: "https://info.tennantco.com/?e=313724&h=29EC564D20B2DD0&l=en&q1=1&as=1"
        },
        {
            path: Face4,
            href: "https://info.tennantco.com/?e=313724&h=29EC564D20B2DD0&l=en&q1=1&as=1"
        },
        {
            path: Face5,
            href: "https://info.tennantco.com/?e=313724&h=29EC564D20B2DD0&l=en&q1=1&as=1"
        },
    ]
    return (
    <div className={styles.likesContainer}>
        <div className={styles.smilesContainer}>
            {faces.map((face, index) => <a key={index} href={face.href}><Img className={''} alt={''} src={face.path}/></a>)}
        </div>
        {/* FIXME: Stringa nelle translations*/}
        <Label text={"Let us know what you think"} className={styles.footerLabel} />
    </div>
    )
}

export default CustomerSurvey
