/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Device } from './Device';
import type { Sites } from './Sites';
import type { UserExtended } from './UserExtended';

export type Notifications = {
    id?: number;
    statusDevice?: Notifications.statusDevice;
    date?: string;
    information?: string;
    unread?: boolean;
    dateCreation?: string;
    userExtended?: UserExtended;
    sites?: Sites;
    device?: Device;
};

export namespace Notifications {

    export enum statusDevice {
        OK = 'OK',
        KO = 'KO',
        WARNING = 'WARNING',
    }


}

