import FilterBar from "@Components/Filter/Filterbar/FilterBar";
import { FilterBarProps } from "@Components/Filter/Filterbar/FilterBar";

export interface DoubleFilterBarProps {
    filterBarData: FilterBarProps
    // filterBarData_2: FilterBarProps
    // buttonClassName: string
    // buttonName: string
}


const DoubleFilterBar = (props: DoubleFilterBarProps) => {
    return (
        <FilterBar {...props.filterBarData} />
    )
}

export default DoubleFilterBar