/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileOutputDTO } from '../models/ProfileOutputDTO';
import type { UserExtendedDTO } from '../models/UserExtendedDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomUserExtendedResourceService {

    /**
     * Retrieve user extended information
     * Returns detailed information about the user with the specified internal user ID.
     * @param userId The User ID to filter by
     * @returns UserExtendedDTO OK
     * @throws ApiError
     */
    public static getUserExtendedByInternalUser(
        userId: number,
    ): CancelablePromise<UserExtendedDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/user-extended/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves current User profile
     * Returns information's about the current user
     * @returns ProfileOutputDTO OK
     * @throws ApiError
     */
    public static getUserProfile(): CancelablePromise<ProfileOutputDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/user-company',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
