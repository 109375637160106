/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MetaDataDTO } from '../models/MetaDataDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomMetadataResourceService {

    /**
     * Retrieves the metadata of input site
     * Get the site toolsense metadata
     * @param siteId The ID of site to filter by
     * @returns MetaDataDTO OK
     * @throws ApiError
     */
    public static getSiteMetadata(
        siteId: number,
    ): CancelablePromise<Array<MetaDataDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/site-metadata',
            query: {
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves the metadata of input device
     * Get the device toolsense metadata
     * @param deviceId The ID of device to filter by
     * @returns MetaDataDTO OK
     * @throws ApiError
     */
    public static getDeviceMetadata(
        deviceId: number,
    ): CancelablePromise<Array<MetaDataDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-metadata',
            query: {
                'deviceId': deviceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
