import React from 'react'
import styles from './MachineUsageHead.module.css'
import Label from '../UI/Label'
import Img from '../UI/Img'

interface MachineUsageHeadProps {
    img: string
    imgClassname?: string
    containerClassname?:string
    label1: string
    label1className?: string
    label2: string
    label2className?: string
    //legendStyle?: string
}
const MachineUsageHead = (props: MachineUsageHeadProps) => {
    return (
        <div className={props.containerClassname}>
            <div className={styles.container}>
                <div className={styles.left_container}>
                    <Img className={props.imgClassname ?? ""} alt={''} src={props.img}/>
                </div>
               
               <div className={styles.right_container}>
                <Label text={props.label1 ?? ""} className={props.label1className ?? ""} />
                <Label text={props.label2 ?? ""} className={props.label2className ?? ""} />
               </div>
    
                
            </div>
    
        </div>
    )
}

export default MachineUsageHead
