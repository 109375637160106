import React from 'react'
import styles from './HorizontalReportActivity.module.css'
import Label from '../../UI/Label'
import Legend from '../../Legend'
import LabelsProgressBar from '../../LabelsProgressBar'
import { useTranslation } from 'react-i18next'
import ReportScore from '../../ReportScore'
import ContentLabel from '@/components/ContentLabel'
import ReportTrend from '@/components/ReportTrend'

interface HorizontalReportActivityProps {
    title: string
    currValue: string
    prevValue: string
    benchmarkValue?: string
    score?: number
    trend?: "up" | "down"
}
const HorizontalReportActivity = (props: HorizontalReportActivityProps) => {

    const { t } = useTranslation();
    return (
        <div className={styles.boxContainer}>
            <Label text={props.title} className={styles.activityTitleLabel} />
            <div className={styles.boxSubContainer}>

                <div className={styles.horizontalContainer} >

                    <LabelsProgressBar type={'vertical'} label1={props.currValue} label1className={styles.titleLabelsProgressbar} label2={t('report.currPeriod')} label2className={styles.subtitleLabelsProgressbar} />
                    <LabelsProgressBar type={'vertical'} label1={props.prevValue} label1className={styles.titleLabelsProgressbar} label2={t('report.prevPeriod')} label2className={styles.subtitleLabelsProgressbar} />
                </div>

                {props.benchmarkValue && <>
                    <div className={styles.verHr} />
                    <LabelsProgressBar type={'vertical'} label1={props.benchmarkValue} label1className={styles.smallTitleLabelsProgressbar} label2={t('report.benchmark')} label2className={styles.smallSubtitleLabelsProgressbar} />
                </>}

                {props.trend && <>
                    <div className={styles.verHr} />
                    <ReportTrend status={props.trend} />
                </>}
                
                {props.score && <>
                    <div className={styles.verHr} /><div className={styles.scoreContainer}>
                        <Label text={t('report.score')} className={styles.scoreLabel} />
                        <ReportScore score={props.score ?? 0} />
                    </div>
                </>}

            </div>
        </div>

    )
}

export default HorizontalReportActivity
