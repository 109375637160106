import Img from "@Ui/Img"
import mask_group from '@Images/MaskGroup.svg'
import logo_top from '@Images/MaxiLogoTop.svg'
import logo_bottom from '@Images/MaxiLogoBottom.svg'
import styles from './ImageLogin.module.css'

const ImageLogin = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.container_logoTop}>
                    <Img src={logo_top} className={styles.logo_top} alt='logo top' />
                </div>
                <div className={styles.container_logoMiddle}>
                    <Img src={mask_group} className={styles.mask_group} alt='gif machine' />
                </div>
                <div className={styles.container_logoBottom}>
                    <Img src={logo_top} className={styles.logo_bottom} alt='logo bottom' />
                </div>
            </div>
        </>
    )
}

export default ImageLogin